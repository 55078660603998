import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../../components/PageTitle.js";
import { useNavigate, useParams } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import {
  getSingleApartments,
  updateApartment,
} from "../../../store/actions/websiteApartments";
import { blockPageAction } from "../../../store/actions/users";
import ModalButtonsReset from "../../../components/modals/modalButtons/ModalButtonsReset";
import { toast } from "react-toastify";
import WebApartmentsForm from "./WebApartmentsForm";

function PreviewWebApartments() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const rowStatus = useSelector((store) => store?.websiteApartments?.status);

  useEffect(() => {
    handleGetSingle(id);
    return () => {
      dispatch(blockPageAction(""));
    };
  }, []);

  const handleGetSingle = (id) => {
    dispatch(
      getSingleApartments(id, (err, data) => {
        if (data && data.apartment) {
          setFormData(data?.apartment);
        }
      })
    );
    return () => {};
  };

  const handleEditConfirm = (data) => {
    dispatch(
      updateApartment(data, (err, data) => {
        if (err) {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
        } else {
          if (data) {
            toast.success("Successfully updated.", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
            navigate("/website-management/website-apartments");
          }
        }
      })
    );
  };

  return (
    <>
      <PageTitle
        parentTitle={"Website Apartments"}
        childTitle={formData.apartment_name || ""}
        parentLink={"/website-management/website-apartments"}
      />
      <div className="form-block">
        {rowStatus === "success" ? (
          <WebApartmentsForm
            submit={handleEditConfirm}
            formData={formData}
            footer={
              <ModalButtonsReset
                submit={handleEditConfirm}
                reset={() => {
                  handleGetSingle(id);
                }}
              />
            }
          />
        ) : (
          <div className="form-loading">
            <FadeLoader
              color={`var(--primary_main)`}
              loading={rowStatus === "pending"}
              size={10}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
      </div>
    </>
  );
}

export default PreviewWebApartments;
