import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Stack from "@mui/material/Stack";
import { createTheme, TextField, ThemeProvider } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";
import Select from "react-select";
import { getAllPickUpPointsAction } from "../../store/actions/pickUpPoints";
import { blockPageAction, getAllSuppliers } from "../../store/actions/users";
import { getAllDirectionsAction } from "../../store/actions/directions";
import { Controller, useForm } from "react-hook-form";
import { TrimmedData } from "../../services/TrimObjectFields";
import { getAllCurrency } from "../../store/actions/currency";
import { isEmpty } from "lodash";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";

function ToursForm({ submit, footer, formData, historyArr }) {
  const allCurrency = useSelector((store) => store.currency.allCurrency);
  const allSuppliers = useSelector((store) => store.users.allSupplierUsers);
  const allDirections = useSelector((store) => store.directions?.allDirections);
  const allPickUpPoints = useSelector(
    (store) => store.pickUpPoints.allPickUpPoints
  );
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const [showAll, setShowAll] = useState(false);

  const {
    reset,
    watch,
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isDirty, isSubmitted },
  } = useForm();

  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllCurrency());
    dispatch(getAllSuppliers());
    dispatch(getAllDirectionsAction());
    dispatch(getAllPickUpPointsAction());
  }, []);

  useEffect(() => {
    if (id && formData) {
      reset(formData);
    }
  }, [formData]);

  useEffect(() => {
    if (id && formData) {
      if (isDirty) {
        setIsFormDisabled(false);
        dispatch(blockPageAction(`/tours/${id}`));
      } else {
        setIsFormDisabled(true);
        dispatch(blockPageAction(""));
      }
    }
  }, [isDirty]);

  const onSubmit = (data) => {
    const trimmedData = TrimmedData(data);
    submit(trimmedData);
  };

  const getCustomNotRequiredStyles = (field) => {
    const isInvalid = errors[field];
    const isValid = isSubmitted && !isInvalid;
    return {
      control: (styles) => ({
        ...styles,
        borderColor: isInvalid ? "#198754" : isValid ? "#198754" : "#ced4da",
      }),
      option: (styles) => ({
        ...styles,
        ":active": {
          ...styles[":active"],
          backgroundColor: `var(--primary_light)`,
        },
      }),
    };
  };

  const getCustomStyles = (field) => {
    const isInvalid = errors[field];
    const isValid = isSubmitted && !isInvalid;
    return {
      control: (styles) => ({
        ...styles,
        borderColor: isInvalid ? "#dc3545" : isValid ? "#198754" : "#ced4da",
      }),
      option: (styles) => ({
        ...styles,
        ":active": {
          ...styles[":active"],
          backgroundColor: `var(--primary_light)`,
        },
      }),
    };
  };

  const getCustomStylesForDate = (field) => {
    const isInvalid = errors[field];
    const isValid = isSubmitted && !isInvalid;

    return createTheme({
      components: {
        MuiOutlinedInput: {
          styleOverrides: {
            notchedOutline: {
              borderColor: isValid ? "#198754" : "#ced4da",
            },
          },
        },
      },
    });
  };
  const getCustomStylesForInput = (field) => {
    const isInvalid = errors[field];
    return {
      borderColor: isSubmitted
        ? isInvalid
          ? "#dc3545"
          : "#198754"
        : "#ced4da",
    };
  };

  const toggleShowAll = (ev) => {
    ev.stopPropagation();
    setShowAll(!showAll);
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="form-block">
          <Modal.Body>
            <Row>
              {id && (
                <Col sm={2}>
                  <Form.Group className="mb-3">
                    <Form.Label>Status</Form.Label>
                    <Form.Control
                      id="tour_status"
                      name="state"
                      disabled={true}
                      {...register("state")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.tour_name
                        ? errors.tour_name.message
                        : "Required Field"}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              )}
              <Col sm={id ? 5 : 6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Tour Name
                    <span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Form.Control
                    id="tour_name"
                    name="tour_name"
                    maxLength={254}
                    isInvalid={isSubmitted && !!errors.tour_name}
                    isValid={isSubmitted && !errors.tour_name}
                    {...register("tour_name", {
                      required: "Required Field",
                      pattern: {
                        value: /^(?!\s*$).+/,
                        message: "Only spaces are not allowed",
                      },
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.tour_name
                      ? errors.tour_name.message
                      : "Required Field"}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={id ? 5 : 6}>
                <Form.Group className="mb-3">
                  <Form.Label>Driver Location Url</Form.Label>
                  <Form.Control
                    pattern="https?://.+"
                    id="tour_driver_location_url"
                    name="driver_location_url"
                    maxLength={254}
                    type="text"
                    isInvalid={isSubmitted && !!errors.driver_location_url}
                    isValid={isSubmitted && !errors.driver_location_url}
                    {...register("driver_location_url", {
                      pattern: {
                        value: /^(?!\s*$).+/,
                        message: "Only spaces are not allowed",
                      },
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.driver_location_url
                      ? errors.driver_location_url.message
                      : "Invalid Format"}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6} lg={3}>
                <Form.Label className="label-m-1r"> Service Date </Form.Label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3} id={"tour_service_date"}>
                    <ThemeProvider
                      theme={getCustomStylesForDate("service_date")}
                    >
                      <DesktopDatePicker
                        name={"service_date"}
                        className="month_year_picker"
                        views={["year", "month", "day"]}
                        value={
                          watch("service_date") && dayjs(watch("service_date"))
                        }
                        maxDate={watch("end_date") && dayjs(watch("end_date"))}
                        onChange={(newValue) => {
                          if (newValue) {
                            setValue(
                              "service_date",
                              dayjs(newValue).format("YYYY-MM-DD"),
                              {
                                shouldDirty: true,
                              }
                            );
                          } else {
                            setValue("service_date", null, {
                              shouldDirty: true,
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            disabled={watch("send") === "true"}
                          />
                        )}
                      />
                    </ThemeProvider>
                  </Stack>
                </LocalizationProvider>
              </Col>
              <Col sm={6} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Time Start</Form.Label>
                  <Form.Control
                    type="time"
                    id="tour_start_time"
                    name="start_time"
                    {...register("start_time")}
                    style={getCustomStylesForInput("start_time")}
                  />
                </Form.Group>
              </Col>
              <Col sm={6} lg={3}>
                <Form.Label className="label-m-1r"> End Date </Form.Label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3} id={"tour_end_date"}>
                    <ThemeProvider
                      theme={getCustomStylesForDate("service_date")}
                    >
                      <DesktopDatePicker
                        name={"end_date"}
                        className="month_year_picker"
                        views={["year", "month", "day"]}
                        minDate={
                          watch("service_date") && dayjs(watch("service_date"))
                        }
                        value={
                          (watch("service_date") &&
                            !watch("end_date") &&
                            dayjs(watch("service_date"))) ||
                          (watch("end_date") && dayjs(watch("end_date")))
                        }
                        onChange={(newValue) => {
                          if (newValue) {
                            setValue(
                              "end_date",
                              dayjs(newValue).format("YYYY-MM-DD"),
                              {
                                shouldDirty: true,
                              }
                            );
                          } else {
                            setValue("end_date", null, {
                              shouldDirty: true,
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            disabled={watch("send") === "true"}
                          />
                        )}
                      />
                    </ThemeProvider>
                  </Stack>
                  {errors?.end_date && watch("end_date") && (
                    <span className="custom-invalid-feedback">
                      Invalid Date
                    </span>
                  )}
                </LocalizationProvider>
              </Col>
              <Col sm={6} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Time Finish</Form.Label>
                  <Form.Control
                    id={"tour_end_time"}
                    name={"end_time"}
                    type="time"
                    min={watch("start_time")}
                    {...register("end_time", {
                      validate: (value) => {
                        const startTime = watch("start_time");
                        if (startTime && value && value <= startTime) {
                          return "Invalid time";
                        }
                        return true;
                      },
                    })}
                    style={getCustomStylesForInput("end_time")}
                  />
                  {errors?.end_time && watch("end_time") && (
                    <span className="custom-invalid-feedback">
                      Invalid Time
                    </span>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6} lg={4}>
                <Form.Group className={!isSubmitted ? "mb-3" : null}>
                  <Form.Label>Pick Up Time</Form.Label>
                  <Form.Control
                    type="time"
                    name={"pick_up_time"}
                    max={watch("end_time")}
                    id={"tour_pick_up_time"}
                    {...register("pick_up_time")}
                    style={getCustomStylesForInput("pick_up_time")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {watch("end_time") && "Invalid time"}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={6} lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Net Price (€)</Form.Label>
                  <Form.Control
                    type="text"
                    maxLength={50}
                    name={"net_price"}
                    id={"tour_net_price"}
                    {...register("net_price")}
                    isValid={isSubmitted && !errors.net_price}
                    isInvalid={isSubmitted && !!errors.net_price}
                    onInput={(e) => {
                      let inputValue = e.target.value;
                      inputValue = inputValue.replace(/[^0-9.]/g, "");

                      const dotCount = (inputValue.match(/\./g) || []).length;
                      if (dotCount > 1) {
                        inputValue = inputValue.substring(
                          0,
                          inputValue.lastIndexOf(".") + 1
                        );
                      }
                      if (inputValue.startsWith(".")) {
                        inputValue = "0" + inputValue;
                      }
                      if (inputValue.includes(".")) {
                        let [integerPart, decimalPart] = inputValue.split(".");
                        decimalPart = decimalPart.substring(0, 2);
                        if (integerPart && integerPart.length > 8) {
                          integerPart = integerPart.substring(0, 8);
                        }
                        inputValue = `${integerPart}.${decimalPart}`;
                      }
                      if (
                        inputValue.startsWith("0") &&
                        inputValue.length > 1 &&
                        inputValue[1] !== "."
                      ) {
                        inputValue = inputValue.substring(1);
                      }
                      if (inputValue.length > 8) {
                        if (!inputValue.includes(".")) {
                          inputValue = inputValue.substring(0, 8) + ".00";
                        } else {
                          let [integerPart, decimalPart] =
                            inputValue.split(".");
                          decimalPart = decimalPart.substring(0, 2);
                          inputValue = `${integerPart}.${decimalPart}`;
                        }
                      }
                      setValue("net_price", inputValue, {
                        shouldDirty: true,
                      });
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.net_price && errors.net_price.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              {/*<Col sm={6} lg={3}>*/}
              {/*  <Form.Group className="mb-3">*/}
              {/*    <Form.Label>*/}
              {/*      Currency*/}
              {/*      {watch("net_price") && !watch("currency_id") && (*/}
              {/*        <span style={{ color: "red" }}> *</span>*/}
              {/*      )}*/}
              {/*    </Form.Label>*/}
              {/*    /!*<Form.Control*!/*/}
              {/*    /!*    id={"tour_currency"}*!/*/}
              {/*    /!*    name={"currency"}*!/*/}
              {/*    /!*    type="text"*!/*/}
              {/*    /!*    isInvalid={isSubmitted && !!errors.currency}*!/*/}
              {/*    /!*    isValid={isSubmitted && !errors.currency}*!/*/}
              {/*    /!*    {...register("currency")}*!/*/}
              {/*    /!*    value={"EUR(€)"}*!/*/}
              {/*/>*/}
              {/*    <Select*/}
              {/*      name="currency_id"*/}
              {/*      // id="tour_currency"*/}
              {/*      id="currency_id"*/}
              {/*      styles={*/}
              {/*        errors.currency_id && !watch("currency_id")*/}
              {/*          ? getCustomStyles("currency_id")*/}
              {/*          : getCustomNotRequiredStyles("currency_id")*/}
              {/*      }*/}
              {/*      options={allCurrency}*/}
              {/*      getOptionLabel={(i) =>*/}
              {/*        `${i.currency_name}(${i.currency_symbol})`*/}
              {/*      }*/}
              {/*      // value={*/}
              {/*      //   watch("currency_id") && Array.isArray(allCurrency)*/}
              {/*      //       ? allCurrency.find((i) => i.id == 2)*/}
              {/*      //       : null*/}
              {/*      // }*/}
              {/*      value={allCurrency.find((i) => i.id === 2) || null}*/}
              {/*      {...register("currency_id", {*/}
              {/*        required:*/}
              {/*          watch("net_price") &&*/}
              {/*          !watch("currency_id") &&*/}
              {/*          "Required Field",*/}
              {/*      })}*/}
              {/*      getOptionValue={(i) => i.id}*/}

              {/*      isClearable={false}*/}
              {/*      isDisabled={true}*/}
              {/*      onChange={(ev) => {*/}
              {/*        setValue("currency_id", ev ? ev.id : null, {*/}
              {/*          shouldDirty: true,*/}
              {/*        });*/}
              {/*      }}*/}
              {/*    />*/}
              {/*    {errors.currency_id && !watch("currency_id") && (*/}
              {/*      <div className="required-message">*/}
              {/*        {errors.currency_id.message}*/}
              {/*      </div>*/}
              {/*    )}*/}
              {/*  </Form.Group>*/}
              {/*</Col>*/}
              <Col sm={6} lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Contact Info</Form.Label>
                  <Form.Control
                    id={"tour_contact_info"}
                    name={"contact_info"}
                    maxLength={254}
                    type="text"
                    isInvalid={isSubmitted && !!errors.contact_info}
                    isValid={isSubmitted && !errors.contact_info}
                    {...register("contact_info", {
                      pattern: {
                        value: /^(?!\s*$).+/,
                        message: "Only spaces are not allowed",
                      },
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.contact_info && errors.contact_info.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6} lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Pick Up Name</Form.Label>
                  <Select
                    id={"tour_pick_up_id"}
                    name={"pick_up_id"}
                    value={
                      watch("pick_up_id") && Array.isArray(allPickUpPoints)
                        ? allPickUpPoints.find(
                            (i) => i.id === watch("pick_up_id")
                          )
                        : {}
                    }
                    options={
                      Array.isArray(allPickUpPoints) ? allPickUpPoints : []
                    }
                    styles={getCustomStyles("pick_up_id")}
                    isClearable={true}
                    getOptionLabel={(i) => i.pick_up_name}
                    getOptionValue={(i) => i.id}
                    onChange={(ev) => {
                      setValue("pick_up_id", ev ? ev.id : null, {
                        shouldDirty: true,
                      });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col sm={6} lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Directions
                    <span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Controller
                    control={control}
                    name="direction_id"
                    rules={{
                      required: "Required Field",
                    }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        id={"tour_direction_name"}
                        name={"direction_id"}
                        value={
                          watch("direction_id") && Array.isArray(allDirections)
                            ? allDirections.find(
                                (i) => i.id === watch("direction_id")
                              )
                            : {}
                        }
                        styles={
                          errors.direction_id && !watch("direction_id")
                            ? getCustomStyles("direction_id")
                            : getCustomNotRequiredStyles("direction_id")
                        }
                        isClearable={true}
                        options={Array.isArray(allDirections) && allDirections}
                        getOptionValue={(i) => i.id}
                        getOptionLabel={(i) => i.direction_name}
                        onChange={(ev) => {
                          setValue("direction_id", ev ? ev.id : null, {
                            shouldDirty: true,
                          });
                        }}
                      />
                    )}
                  />
                  {errors.direction_id && !watch("direction_id") ? (
                    <small style={{ color: "#dc3545" }}>
                      {errors?.direction_id?.message}
                    </small>
                  ) : null}
                </Form.Group>
              </Col>
              <Col sm={12} lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Suppliers
                    <span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Controller
                    control={control}
                    name="supplier_user_id"
                    rules={{
                      required: "Required Field",
                    }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        id={"supplier_user_id"}
                        name={"supplier_user_id"}
                        value={
                          watch("supplier_user_id") &&
                          Array.isArray(allSuppliers)
                            ? allSuppliers.find(
                                (i) => i.id === watch("supplier_user_id")
                              )
                            : {}
                        }
                        styles={
                          errors.supplier_user_id && !watch("supplier_user_id")
                            ? getCustomStyles("supplier_user_id")
                            : getCustomNotRequiredStyles("supplier_user_id")
                        }
                        isDisabled={
                          (formData && formData?.state === "Pending") ||
                          (formData && formData?.state === "Accepted") ||
                          (formData && formData?.state === "Rejected")
                        }
                        isClearable={true}
                        options={allSuppliers}
                        getOptionValue={(i) => i.id}
                        getOptionLabel={(i) => i.full_name}
                        onChange={(ev) => {
                          setValue("supplier_user_id", ev ? ev.id : null, {
                            shouldDirty: true,
                          });
                        }}
                      />
                    )}
                  />
                  {errors.supplier_user_id && !watch("supplier_user_id") ? (
                    <small style={{ color: "#dc3545" }}>
                      {errors?.supplier_user_id?.message}
                    </small>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>
            {id && !isEmpty(historyArr) && historyArr.length > 0 ? (
              <>
                <h5>
                  <strong>Tour History</strong>
                </h5>
                <div className="history_info_div">
                  {historyArr &&
                    historyArr
                      ?.slice(0, showAll ? historyArr.length : 1)
                      ?.map((i, index) => {
                        let modifiedHistory = i.replace(
                          /null|undefined|:\s*,|:\s*$/g,
                          "N/A"
                        );
                        return (
                          <>
                            <p
                              key={index}
                              dangerouslySetInnerHTML={{
                                __html: modifiedHistory.replace(/;/g, "<br/>"),
                              }}
                            ></p>
                          </>
                        );
                      })}

                  {!isEmpty(historyArr) && historyArr?.length > 1 ? (
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={(ev) => toggleShowAll(ev)}
                    >
                      {showAll && historyArr?.length > 0 ? (
                        <strong style={{ fontSize: "13px" }}>
                          Show Less <MdOutlineKeyboardArrowUp />
                        </strong>
                      ) : (
                        <strong style={{ fontSize: "13px" }}>
                          Show More <MdOutlineKeyboardArrowDown />
                        </strong>
                      )}
                    </span>
                  ) : null}
                </div>
              </>
            ) : null}
          </Modal.Body>
        </div>
        <Modal.Footer>
          {React.cloneElement(footer, { disabled: isFormDisabled })}
        </Modal.Footer>
      </Form>
    </>
  );
}

export default ToursForm;
