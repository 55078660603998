import {
  FaDog,
  FaPumpSoap,
  FaSpa,
  FaSwimmingPool,
  FaWifi,
} from "react-icons/fa";
import { LuParkingCircle, LuRefrigerator } from "react-icons/lu";
import { GiCommercialAirplane, GiWashingMachine } from "react-icons/gi";
import { IoBedSharp, IoRestaurant } from "react-icons/io5";
import {
  MdBalcony,
  MdBathtub,
  MdCurrencyExchange,
  MdDeck,
  MdFitnessCenter,
  MdLocalBar,
  MdOutlineBathroom,
  MdOutlinePool,
} from "react-icons/md";
import { ImTv } from "react-icons/im";
import { FaKitchenSet } from "react-icons/fa6";
import { BiCloset } from "react-icons/bi";
import { TbDisabled } from "react-icons/tb";
import { IoLogoNoSmoking } from "react-icons/io";
import { Ri24HoursLine } from "react-icons/ri";
import { GrElevator } from "react-icons/gr";

export const react_icons = [
  { symbol: "wi_fi", icon: <FaWifi /> },
  { symbol: "parking", icon: <LuParkingCircle /> },
  { symbol: "airport_shuttle", icon: <GiCommercialAirplane /> },
  { symbol: "restaurant", icon: <IoRestaurant /> },
  { symbol: "outdoor_pool", icon: <MdOutlinePool /> },
  { symbol: "private_pool", icon: <FaSwimmingPool /> },
  { symbol: "balcony", icon: <MdBalcony /> },
  { symbol: "bathtub", icon: <MdBathtub /> },
  { symbol: "terrace", icon: <MdDeck /> },
  { symbol: "sauna", icon: <MdOutlineBathroom /> },
  { symbol: "spa", icon: <FaSpa /> },
  { symbol: "fitness_center", icon: <MdFitnessCenter /> },
  { symbol: "elevator", icon: <GrElevator /> },
  { symbol: "air_conditioning" },
  { symbol: "kitchen_kitchenette", icon: <FaKitchenSet /> },
  { symbol: "tv", icon: <ImTv /> },
  { symbol: "wardrobe_closet", icon: <BiCloset /> },
  { symbol: "hairdryer" },
  { symbol: "refrigerator", icon: <LuRefrigerator /> },
  { symbol: "minibar", icon: <MdLocalBar /> },
  { symbol: "washing_machine", icon: <GiWashingMachine /> },
  { symbol: "free_toiletries", icon: <FaPumpSoap /> },
  { symbol: "pet_friendly", icon: <FaDog /> },
  { symbol: "family_rooms", icon: <IoBedSharp /> },
  { symbol: "currency_exchange", icon: <MdCurrencyExchange /> },
  { symbol: "non_smoking_rooms", icon: <IoLogoNoSmoking /> },
  { symbol: "facilities_for_disabled_guests", icon: <TbDisabled /> },
  { symbol: "24_hour_front_desk", icon: <Ri24HoursLine /> },
];
