import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { verifyUserEmail, checkUserEmail } from "../../store/actions/users";
import { toast } from "react-toastify";
import logoSVG from "../../../src/assets/images/white-logo.svg";
import { titleFromEnv } from "../../services/utils";
import { DateTime } from "luxon";
const { REACT_APP_TYPE, REACT_APP_SINGLE_NAME } = process.env;

function UserVerify() {
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState();
  const [verification_code, setVerificationCode] = useState("");
  const { hash } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (hash) {
      setIsLoading(true);
      dispatch(
        checkUserEmail(hash, (err, data) => {
          if (err) {
            toast.error(err, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
            navigate("/login");
          } else if (data?.user) {
            const expirationDate = DateTime.fromISO(
              data.user.activationExpirationEmailDate,
              { zone: "utc" }
            )
              .toUTC()
              .plus({ days: 2 });

            const now = DateTime.utc();

            if (now > expirationDate) {
              toast.error(
                "The activation link has expired. Please contact System Administrator.",
                {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 5000,
                }
              );
              navigate("/login");
              return;
            }

            if (data.user.status === "NoPassword") {
              setTimeout(() => {
                navigate(`/set-password/${hash}`);
              }, 3000);
            }
            setUser(data?.user);
            setIsLoading(false);
          }
        })
      );
    } else {
      navigate("/login");
    }
  }, []);

  const handleVerify = (ev) => {
    ev.preventDefault();
    if (verification_code && verification_code.length === 6) {
      dispatch(
        verifyUserEmail({ user, verification_code }, (err, data) => {
          if (err) {
            toast.error(err, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
          }
          if (data && data.status === 200) {
            toast.success("Successfully Verified", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
            navigate(`/login`);
          }
        })
      );
    }
  };

  return (
    <div className="login-page parent clearfix">
      <div className="login_page_overlay">
        <div className="login">
          <div className="container">
            <h3 className="login-page_logo">
              {REACT_APP_TYPE === "single" && REACT_APP_SINGLE_NAME ? (
                <h3 className="login-page_title" style={{ fontSize: "1.4em" }}>
                  {titleFromEnv(REACT_APP_SINGLE_NAME)}
                </h3>
              ) : (
                <h3 className="login-page_logo">
                  <img src={logoSVG} width={"290px"} />
                </h3>
              )}
            </h3>
            {/*<h4 className="login-page_title">Confirm Email</h4>*/}
            <span className="login-page-text">
              Please wait for verification <div class="loader"></div>
            </span>

            {/*<Form onSubmit={handleVerify} className="verify_form">*/}
            {/*  <div className="d-flex justify-content-center">*/}
            {/*    <AuthCode*/}
            {/*      verification_code={verification_code}*/}
            {/*      setVerificationCode={setVerificationCode}*/}
            {/*    />*/}
            {/*  </div>*/}

            {/*  /!*<LoadingButton*!/*/}
            {/*  /!*  size="big"*!/*/}
            {/*  /!*  className="login_submit_btn btn btn-primary login_button"*!/*/}
            {/*  /!*  loading={isLoading}*!/*/}
            {/*  /!*  loadingPosition="start"*!/*/}
            {/*  /!*  variant="contained"*!/*/}
            {/*  /!*  type="submit"*!/*/}
            {/*  /!*>*!/*/}
            {/*  /!*  <span>Next</span>*!/*/}
            {/*  /!*</LoadingButton>*!/*/}
            {/*</Form>*/}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserVerify;
