import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../../components/PageTitle.js";
import { useNavigate, useParams } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import { getSingleTour, updateTour } from "../../../store/actions/websiteTours";
import { blockPageAction } from "../../../store/actions/users";
import ModalButtonsReset from "../../../components/modals/modalButtons/ModalButtonsReset";
import { toast } from "react-toastify";
import WebToursForm from "./WebToursForm";

function PreviewWebTours() {
  const rowStatus = useSelector((store) => store?.websiteTours?.status);
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    handleGetSingle(id);
    return () => {
      dispatch(blockPageAction(""));
    };
  }, []);

  const handleGetSingle = (id) => {
    dispatch(
      getSingleTour(id, (err, data) => {
        if (data && data.tour) {
          setFormData(data?.tour);
        }
      })
    );
    return () => {};
  };

  const handleEditConfirm = (data) => {
    dispatch(
      updateTour(data, (err, data) => {
        if (err) {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
        } else {
          if (data) {
            toast.success("Successfully updated.", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
            navigate("/website-management/website-tours");
          }
        }
      })
    );
  };

  return (
    <>
      <PageTitle
        parentTitle={"Website Tours"}
        childTitle={formData.tour_name || ""}
        parentLink={"/website-management/website-tours"}
      />
      <div className="form-block">
        {rowStatus === "success" ? (
          <WebToursForm
            submit={handleEditConfirm}
            formData={formData}
            footer={
              <ModalButtonsReset
                submit={handleEditConfirm}
                reset={() => {
                  handleGetSingle(id);
                }}
              />
            }
          />
        ) : (
          <div className="form-loading">
            <FadeLoader
              color={`var(--primary_main)`}
              loading={rowStatus === "pending"}
              size={10}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
      </div>
    </>
  );
}

export default PreviewWebTours;
