import {
  GET_ALL_FACILITIES_REQUEST,
  GET_ALL_FACILITIES_SUCCESS,
  GET_ALL_FACILITIES_FAIL,
  GET_ALL_ROOM_FACILITIES_REQUEST,
  GET_ALL_ROOM_FACILITIES_SUCCESS,
  GET_ALL_ROOM_FACILITIES_FAIL,
} from "../actions/facilities";

const initialState = {
  status: "",
  allFacilities: [],
  allRoomFacilities: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_FACILITIES_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case GET_ALL_FACILITIES_SUCCESS: {
      return {
        ...state,
        status: "success",
        allFacilities: action.payload.data.facilities,
      };
    }
    case GET_ALL_FACILITIES_FAIL: {
      return {
        ...state,
        status: "fail",
      };
    }

    case GET_ALL_ROOM_FACILITIES_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case GET_ALL_ROOM_FACILITIES_SUCCESS: {
      return {
        ...state,
        status: "success",
        allRoomFacilities: action.payload.data.room_facilities,
      };
    }
    case GET_ALL_ROOM_FACILITIES_FAIL: {
      return {
        ...state,
        status: "fail",
      };
    }

    default: {
      return state;
    }
  }
}
