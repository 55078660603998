import { GridActionsCellItem } from "@mui/x-data-grid";
import { MdDelete, MdEdit } from "react-icons/md";
import { FaToggleOff, FaToggleOn } from "react-icons/fa";
import React from "react";
import moment from "moment";
import { react_icons } from "../Facilities";
import { GrGallery } from "react-icons/gr";
import { FaHotel } from "react-icons/fa6";
import { IoBookmark, IoBookmarkOutline } from "react-icons/io5";

export const getColumns = ({
  role,
  handleDelete,
  redirectToPreview,
  handleUpdateStatus,
  handleNavigateGallery,
  handleNavigateToRooms,
  handleShowFeaturedStatusModal,
}) => {
  return [
    {
      access: ["Admin", "Supplier", "Tour Manager"],
      field: "id",
      headerName: "#",
      width: 70,
    },
    {
      access: ["Admin", "Supplier", "Tour Manager"],
      field: "status",
      headerName: "Status",
      width: 100,
    },
    {
      access: ["Admin", "Supplier", "Tour Manager"],
      field: "villa_name",
      headerName: "Villa name",
      width: 100,
    },
    {
      access: ["Admin", "Supplier", "Tour Manager"],
      field: "price",
      headerName: "Price (€)",
      width: 100,
    },
    // {
    //   access: ["Admin", "Supplier", "Tour Manager"],
    //   field: "currency",
    //   headerName: "Currency",
    //   width: 80,
    //   renderCell: ({ row }) => {
    //     return row.currency
    //       ? row.currency.currency_name + `(${row.currency.currency_symbol})`
    //       : "";
    //   },
    // },
    {
      access: ["Admin", "Supplier", "Tour Manager"],
      field: "facilities",
      headerName: "Facilities",
      width: 250,
      renderCell: ({ row }) => {
        return row.facilities && Array.isArray(row.facilities)
          ? row.facilities
              .map((facility) => facility.facilityId?.facility_name)
              .join(" | ")
          : "";
      },
    },
    {
      access: ["Admin", "Supplier", "Tour Manager"],
      field: "country",
      headerName: "Country",
      width: 150,
    },
    {
      access: ["Admin", "Supplier", "Tour Manager"],
      field: "state",
      headerName: "State",
      width: 150,
    },
    {
      access: ["Admin", "Supplier", "Tour Manager"],
      field: "city",
      headerName: "City",
      width: 150,
    },
    {
      access: ["Admin", "Supplier", "Tour Manager"],
      field: "address",
      headerName: "Address",
      width: 150,
    },
    {
      access: ["Admin", "Supplier", "Tour Manager"],
      field: "zip_code",
      headerName: "Zip Code",
      width: 100,
    },
    {
      access: ["Admin", "Tour Manager"],
      field: "createdAt",
      headerName: "Created Date",
      width: 200,
      renderCell: (params) =>
        moment(params?.createdAt || params?.row.createdAt).format(
          "MM/DD/YYYY HH:mm:ss"
        ),
    },
    {
      access: ["Admin", "Tour Manager"],
      field: "createdBy.full_name",
      headerName: "Created By",
      width: 150,
      renderCell: ({ row }) => row?.createdBy?.full_name || "",
    },
    {
      access: ["Admin", "Tour Manager"],
      field: "updatedAt",
      headerName: "Modified Date",
      width: 200,
      renderCell: (params) =>
        moment(params?.updatedAt || params?.row.updatedAt).format(
          "MM/DD/YYYY HH:mm:ss"
        ),
    },
    {
      access: ["Admin", "Tour Manager"],
      field: "updatedBy.full_name",
      headerName: "Modified By",
      width: 150,
      renderCell: ({ row }) => row?.updatedBy?.full_name || "",
    },
    {
      headerName: "Actions",
      field: "actions",
      cellClassName: "actions",
      type: "actions",
      access: ["Admin", "Supplier", "Tour Manager"],
      width: 220,
      getActions: (params) =>
        [
          <GridActionsCellItem
            label="View Gallery"
            title="View Gallery"
            icon={<GrGallery size={19} />}
            access={["Admin", "Tour Manager", "Supplier"]}
            onClick={() => handleNavigateGallery(params?.row?.id || params?.id)}
          />,
          <GridActionsCellItem
            label="View Rooms"
            title="View Rooms"
            icon={<FaHotel size={18} />}
            access={["Admin", "Tour Manager", "Supplier"]}
            onClick={() => handleNavigateToRooms(params?.row?.id || params?.id)}
          />,
          <GridActionsCellItem
            onClick={() => redirectToPreview(params?.row?.id || params?.id)}
            className="test1"
            icon={<MdEdit />}
            label="Edit"
            title="View/Edit"
            access={["Admin", "Tour Manager"]}
            width={50}
          />,
          <GridActionsCellItem
            style={{
              cursor: "pointer",
            }}
            onClick={() => handleUpdateStatus(params?.row || params)}
            icon={
              params?.status === "Active" ||
              params?.row?.status === "Active" ? (
                <FaToggleOn />
              ) : (
                <FaToggleOff />
              )
            }
            label="Status"
            title="Change Status"
            access={["Admin", "Tour Manager"]}
            width={50}
          />,
          <GridActionsCellItem
            label="Featured"
            title="Mark as Featured"
            onClick={(ev) =>
              handleShowFeaturedStatusModal(ev, params?.row?.id || params?.id)
            }
            icon={
              params?.type === "Featured" || params.row?.type === "Featured" ? (
                <IoBookmark size={15} />
              ) : (
                <IoBookmarkOutline size={15} />
              )
            }
            access={["Admin", "Tour Manager"]}
          />,
          <GridActionsCellItem
            icon={<MdDelete />}
            label="Delete"
            title="Delete"
            onClick={(ev) => handleDelete(ev, params?.row || params)}
            access={["Admin", "Tour Manager"]}
            width={50}
          />,
        ].filter(({ props }) => {
          return props.access.includes(role);
        }),
    },
  ].filter((r) => r.access.includes(role));
};
