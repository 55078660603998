import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getWebVillaGallery,
  updateWebVillaGallery,
} from "../../../store/actions/websiteVillas";
import PageTitle from "../../../components/PageTitle";
import { FadeLoader } from "react-spinners";
import ModalButtonsReset from "../../../components/modals/modalButtons/ModalButtonsReset";
import WebsiteGalleryForm from "../../../components/WebsiteGallery/WebsiteGalleryForm";
import { toast } from "react-toastify";

function WebVillaGallery() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState();
  const rowStatus = useSelector((store) => store?.websiteVillas?.status);

  useEffect(() => {
    handleGetGallery(id);
  }, []);

  const handleGetGallery = (id) => {
    dispatch(
      getWebVillaGallery(id, (err, data) => {
        if (!err && data) {
          setFormData(data?.villas);
        }
      })
    );
  };
  const handleEditConfirm = (images) => {
    const allFormImages = { ...images };
    dispatch(
      updateWebVillaGallery(id, allFormImages, (err, data) => {
        if (err) {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
        }
        if (!err && data) {
          toast.success("Successfully updated.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          navigate("/website-management/website-villas");
        }
      })
    );
  };

  return (
    <>
      <PageTitle
        parentTitle={"Website Villas Gallery"}
        childTitle={formData?.villa_name || ""}
        parentLink={"/website-management/website-villas"}
      />
      {formData?.images && rowStatus === "success" ? (
        <WebsiteGalleryForm
          submit={handleEditConfirm}
          gallery={formData?.images}
          main_image={formData?.main_image}
          footer={
            <ModalButtonsReset
              submit={handleEditConfirm}
              reset={() => {
                handleGetGallery(id);
              }}
            />
          }
        />
      ) : (
        <div className="form-loading">
          <FadeLoader
            color={`var(--primary_main)`}
            loading={rowStatus === "pending"}
            size={10}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </>
  );
}

export default WebVillaGallery;
