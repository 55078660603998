import {
  ADD_WEB_TOUR_REQUEST,
  ADD_WEB_TOUR_SUCCESS,
  ADD_WEB_TOUR_FAIL,
  UPDATE_WEB_TOUR_REQUEST,
  UPDATE_WEB_TOUR_SUCCESS,
  UPDATE_WEB_TOUR_FAIL,
  DELETE_WEB_TOUR_REQUEST,
  DELETE_WEB_TOUR_SUCCESS,
  DELETE_WEB_TOUR_FAIL,
  GET_ALL_WEB_TOURS_REQUEST,
  GET_SINGLE_WEB_TOUR_REQUEST,
  GET_SINGLE_WEB_TOUR_SUCCESS,
  GET_ALL_WEB_TOURS_SUCCESS,
  GET_ALL_WEB_TOURS_FAIL,
  GET_SINGLE_WEB_TOUR_FAIL,
  GET_WEB_TOURS_REQUEST,
  GET_WEB_TOURS_SUCCESS,
  GET_WEB_TOURS_FAIL,
  GET_SELECT_WEB_TOURS_SUCCESS,
  GET_SELECT_WEB_TOURS_REQUEST,
  EXPORT_WEB_TOURS_REQUEST,
  EXPORT_WEB_TOURS_SUCCESS,
  EXPORT_WEB_TOURS_FAIL,
  UPDATE_WEB_TOUR_PICKUP_REQUEST,
  UPDATE_WEB_TOUR_PICKUP_SUCCESS,
  UPDATE_WEB_TOUR_PICKUP_FAIL,
  STATUS_WEB_TOUR_REQUEST,
  STATUS_WEB_TOUR_SUCCESS,
  STATUS_WEB_TOUR_FAIL,
  GET_WEB_TOURS_GALLERY_REQUEST,
  GET_WEB_TOURS_GALLERY_SUCCESS,
  GET_WEB_TOURS_GALLERY_FAIL,
  UPDATE_WEB_TOURS_GALLERY_REQUEST,
  UPDATE_WEB_TOURS_GALLERY_SUCCESS,
  UPDATE_WEB_TOURS_GALLERY_FAIL,
  UPDATE_WEB_TOUR_TYPE_REQUEST,
  UPDATE_WEB_TOUR_TYPE_SUCCESS,
  UPDATE_WEB_TOUR_TYPE_FAIL,
  FETCH_WEB_TOUR_REQUEST,
  FETCH_WEB_TOUR_SUCCESS,
  FETCH_WEB_TOUR_FAIL,
} from "../actions/websiteTours";

const initialState = {
  tours: [],
  status: "",
  allTours: [],
  singleTour: {},
  tourGallery: [],
  selectTours: [],
  fetchStatus: "",
  actionStatus: "",
  exportExcelStatus: "",
  getSelectionToursStatus: "",
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_WEB_TOUR_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case ADD_WEB_TOUR_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case ADD_WEB_TOUR_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case UPDATE_WEB_TOUR_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case UPDATE_WEB_TOUR_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case UPDATE_WEB_TOUR_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case DELETE_WEB_TOUR_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case DELETE_WEB_TOUR_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case DELETE_WEB_TOUR_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case GET_ALL_WEB_TOURS_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case GET_ALL_WEB_TOURS_SUCCESS: {
      return {
        ...state,
        status: "success",
        allTours: action.payload.data.result,
      };
    }
    case GET_ALL_WEB_TOURS_FAIL: {
      return {
        ...state,
        status: "fail",
      };
    }

    case GET_SINGLE_WEB_TOUR_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case GET_SINGLE_WEB_TOUR_SUCCESS: {
      return {
        ...state,
        status: "success",
        singleTour: action.payload.data.result,
      };
    }
    case GET_SINGLE_WEB_TOUR_FAIL: {
      return {
        ...state,
        status: "pending",
      };
    }

    case GET_WEB_TOURS_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case GET_WEB_TOURS_SUCCESS: {
      return {
        ...state,
        status: "success",
        tours: action.payload.data.tours,
        count: action.payload.data.count,
      };
    }
    case GET_WEB_TOURS_FAIL: {
      return {
        ...state,
        status: "fail",
      };
    }

    case GET_SELECT_WEB_TOURS_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case GET_SELECT_WEB_TOURS_SUCCESS: {
      return {
        ...state,
        selectTours: action.payload.data.tours,
        status: "success",
      };
    }

    case UPDATE_WEB_TOUR_PICKUP_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case UPDATE_WEB_TOUR_PICKUP_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case UPDATE_WEB_TOUR_PICKUP_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case STATUS_WEB_TOUR_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case STATUS_WEB_TOUR_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case STATUS_WEB_TOUR_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case EXPORT_WEB_TOURS_REQUEST: {
      return {
        ...state,
        exportExcelStatus: "pending",
      };
    }
    case EXPORT_WEB_TOURS_SUCCESS: {
      return {
        ...state,
        exportExcelStatus: "success",
      };
    }
    case EXPORT_WEB_TOURS_FAIL: {
      return {
        ...state,
        exportExcelStatus: "fail",
      };
    }

    case GET_WEB_TOURS_GALLERY_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case GET_WEB_TOURS_GALLERY_SUCCESS: {
      return {
        ...state,
        status: "success",
        tourGallery: action.payload.data?.gallery,
      };
    }
    case GET_WEB_TOURS_GALLERY_FAIL: {
      return {
        ...state,
        status: "fail",
      };
    }

    case UPDATE_WEB_TOURS_GALLERY_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case UPDATE_WEB_TOURS_GALLERY_SUCCESS: {
      return {
        ...state,
        status: "success",
      };
    }
    case UPDATE_WEB_TOURS_GALLERY_FAIL: {
      return {
        ...state,
        status: "fail",
      };
    }

    case UPDATE_WEB_TOUR_TYPE_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case UPDATE_WEB_TOUR_TYPE_SUCCESS: {
      return {
        ...state,
        status: "success",
      };
    }
    case UPDATE_WEB_TOUR_TYPE_FAIL: {
      return {
        ...state,
        status: "fail",
      };
    }

    case FETCH_WEB_TOUR_REQUEST: {
      return {
        ...state,
        status: "pending",
        fetchStatus: "pending",
      };
    }
    case FETCH_WEB_TOUR_SUCCESS: {
      return {
        ...state,
        status: "success",
        fetchStatus: "success",
      };
    }
    case FETCH_WEB_TOUR_FAIL: {
      return {
        ...state,
        status: "fail",
        fetchStatus: "fail",
      };
    }

    default: {
      return state;
    }
  }
}
