import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../../../components/PageTitle.js";
import { useNavigate, useParams } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import {
  getSingleHotelRoom,
  updateHotelRoom,
} from "../../../../store/actions/websiteHotels";
import { blockPageAction } from "../../../../store/actions/users";
import ModalButtonsReset from "../../../../components/modals/modalButtons/ModalButtonsReset";
import { toast } from "react-toastify";
import HotelRoomsForm from "./HotelRoomsForm";

function PreviewHotelRoom() {
  const { id, roomId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const rowStatus = useSelector((store) => store?.websiteHotels?.status);

  useEffect(() => {
    handleGetSingle(roomId);
    return () => {
      dispatch(blockPageAction(""));
    };
  }, []);

  const handleGetSingle = (roomId) => {
    dispatch(
      getSingleHotelRoom(roomId, (err, data) => {
        if (data && data.hotelRoom) {
          setFormData(data?.hotelRoom);
        }
      })
    );
    return () => {};
  };

  const handleEditConfirm = (data) => {
    dispatch(
      updateHotelRoom(data, (err, data) => {
        if (err) {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
        } else {
          if (data) {
            toast.success("Successfully updated.", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
            navigate(`/website-management/website-hotels/rooms/${id}`);
          }
        }
      })
    );
  };

  return (
    <>
      <PageTitle
        parentTitle={"Hotel Rooms"}
        childTitle={formData.room_name || ""}
        parentLink={`/website-management/website-hotels/rooms/${id}`}
      />
      <div className="form-block">
        {rowStatus === "success" ? (
          <HotelRoomsForm
            submit={handleEditConfirm}
            formData={formData}
            footer={
              <ModalButtonsReset
                submit={handleEditConfirm}
                reset={() => {
                  handleGetSingle(id);
                }}
              />
            }
          />
        ) : (
          <div className="form-loading">
            <FadeLoader
              color={`var(--primary_main)`}
              loading={rowStatus === "pending"}
              size={10}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
      </div>
    </>
  );
}

export default PreviewHotelRoom;
