import { GridActionsCellItem } from "@mui/x-data-grid";
import {
  MdDelete,
  MdEdit,
  MdMyLocation,
  MdOutlineEditLocation,
} from "react-icons/md";
import { FaHistory } from "react-icons/fa";
import React from "react";
import moment from "moment";
import { TbTableExport } from "react-icons/tb";
import { Typography } from "@material-ui/core";
import { BiCommentDetail } from "react-icons/bi";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { BsFillSendFill } from "react-icons/bs";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: "400px",
    display: "flex",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

export const getColumns = ({
  redirectToPreview,
  redirectToPreviewVoucher,
  handleDelete,
  handleEditPickUpPoint,
  role,
  handleOpenDriverModal,
  handleOpenSendEmailToSupplier,
  handleState,
}) => {
  return [
    {
      access: ["Admin", "Supplier", "Tour Manager"],
      field: "id",
      headerName: "#",
      width: 70,
    },
    {
      access: ["Admin", "Supplier", "Tour Manager"],
      field: "state",
      headerName: "Status",
      width: 100,
      renderCell: ({ row }) => {
        if (row?.state === "Rejected") {
          return (
            <div style={{ display: "flex", cursor: "pointer" }}>
              <HtmlTooltip
                className="loggerTooltip"
                style={{
                  flexWrap: "wrap",
                  backgroundColor: "transparent",
                  width: "300px",
                  cursor: "pointer",
                }}
                title={
                  window?.innerWidth < 1024 ? null : (
                    <React.Fragment>
                      <div
                        style={{
                          overflowY: "hidden",
                        }}
                      >
                        <Typography color="inherit">
                          <p style={{ width: "100%", fontSize: "0.9em" }}>
                            {row?.cancellation_reason}
                          </p>
                        </Typography>
                      </div>
                    </React.Fragment>
                  )
                }
              >
                <div style={{ display: "flex" }}>
                  {row?.state}{" "}
                  <BiCommentDetail size={18} style={{ marginLeft: "10px" }} />
                </div>
              </HtmlTooltip>
            </div>
          );
        } else {
          return row?.state;
        }
      },
    },
    {
      access: ["Admin", "Supplier", "Tour Manager"],
      field: "tour_name",
      headerName: "Tour name",
      width: 100,
    },
    {
      access: ["Admin", "Supplier", "Tour Manager"],
      field: "direction.direction_name",
      headerName: "Direction Name",
      width: 150,
      renderCell: ({ row }) => {
        if (role === "Supplier") {
          return row?.direction?.direction_name || "";
        } else {
          if (row.direction_id) {
            return (
              <span
                onClick={() =>
                  redirectToPreview(row?.direction_id, "directions")
                }
                className="link-span-in-table"
              >
                {row?.direction?.direction_name || ""}
              </span>
            );
          }
        }
      },
    },
    {
      access: ["Admin", "Supplier", "Tour Manager"],
      field: "driver_location_url",
      headerName: "Driver Location Url",
      width: 150,
      renderCell: (params) => {
        const url =
          params?.driver_location_url || params.row?.driver_location_url;
        if (url) {
          return (
            <span className="link-span-in-table">
              <a href={url} target="_blank">
                {url}
              </a>
            </span>
          );
        } else {
          return "";
        }
      },
    },
    {
      access: ["Admin", "Supplier", "Tour Manager"],
      field: "supplierUser.full_name",
      headerName: "Supplier Name",
      width: 150,
      renderCell: ({ row }) => {
        if (role === "Supplier") {
          return row?.supplierUser?.full_name || "";
        } else {
          if (row?.supplier_user_id) {
            return (
              <span
                onClick={() =>
                  redirectToPreview(row?.supplier_user_id, "users")
                }
                className="link-span-in-table"
              >
                {row?.supplierUser?.full_name || ""}
              </span>
            );
          }
        }
      },
    },
    {
      access: ["Admin", "Supplier", "Tour Manager"],
      field: "pickUpPoint.pick_up_name",
      headerName: "Pick-Up Name",
      width: 150,
      renderCell: ({ row }) => {
        if (role === "Supplier") {
          return row?.pickUpPoint?.pick_up_name || "";
        } else {
          if (row?.pick_up_id) {
            return (
              <span
                onClick={() =>
                  redirectToPreview(row?.pick_up_id, "pick_up_points")
                }
                className="link-span-in-table"
              >
                {row?.pickUpPoint?.pick_up_name || ""}
              </span>
            );
          }
        }
      },
    },
    {
      access: ["Admin", "Supplier", "Tour Manager"],
      field: "service_date",
      headerName: "Service Date",
      width: 150,
      renderCell: ({ row }) => {
        if (row?.service_date) {
          return moment(row.service_date).format("MM/DD/YYYY");
        }
      },
    },
    {
      access: ["Admin", "Supplier", "Tour Manager"],
      field: "end_date",
      headerName: "End Date",
      width: 150,
      renderCell: ({ row }) => {
        if (row?.end_date) {
          return moment(row.end_date).format("MM/DD/YYYY");
        }
      },
    },
    {
      access: ["Admin", "Supplier", "Tour Manager"],
      field: "pick_up_time",
      headerName: "Pick Up Time",
      width: 150,
      renderCell: ({ row }) => {
        if (row?.pick_up_time) {
          const formattedTime = moment(row.pick_up_time, "HH:mm").format(
            "hh:mm A"
          );
          return <span>{formattedTime}</span>;
        }
      },
    },
    {
      access: ["Admin", "Supplier", "Tour Manager"],
      field: "start_time",
      headerName: "Start Time",
      width: 150,
      renderCell: ({ row }) => {
        if (row?.start_time) {
          const formattedTime = moment(row.start_time, "HH:mm").format(
            "hh:mm A"
          );
          return <span>{formattedTime}</span>;
        }
      },
    },
    {
      access: ["Admin", "Supplier", "Tour Manager"],
      field: "end_time",
      headerName: "End Time",
      width: 150,
      renderCell: ({ row }) => {
        if (row?.end_time) {
          const formattedTime = moment(row.end_time, "HH:mm").format("hh:mm A");
          return <span>{formattedTime}</span>;
        }
      },
    },
    {
      access: ["Admin", "Tour Manager"],
      field: "net_price",
      headerName: "Net Price (€)",
      width: 150,
    },
    // {
    //   access: ["Admin", "Supplier", "Tour Manager"],
    //   field: "currency.currency_name",
    //   headerName: "Currency",
    //   width: 80,
    //   renderCell: ({ row }) => {
    //     return row.currency ? (
    //       <span>
    //         {row.currency.currency_name + `(${row.currency.currency_symbol})`}
    //       </span>
    //     ) : (
    //       ""
    //     );
    //   },
    // },
    {
      access: ["Admin", "Supplier", "Tour Manager"],
      field: "contact_info",
      headerName: "Contact Info",
      width: 200,
    },
    {
      access: ["Admin", "Supplier", "Tour Manager"],
      field: "adult_sum",
      headerName: "Adults",
      width: 80,
    },
    {
      access: ["Admin", "Supplier", "Tour Manager"],
      field: "child_sum",
      headerName: "Children",
      width: 80,
    },
    {
      access: ["Admin", "Supplier", "Tour Manager"],
      field: "infant_sum",
      headerName: "Infants",
      width: 80,
    },
    {
      access: ["Admin", "Supplier", "Tour Manager"],
      field: "total_passengers",
      headerName: "Total Passengers",
      width: 140,
      renderCell: ({ row }) => {
        return (
          row?.allPassengers?.toString() ||
          row?.total_passengers?.toString() ||
          "0"
        );
      },
    },
    {
      access: ["Admin", "Tour Manager"],
      field: "createdAt",
      headerName: "Created Date",
      width: 200,
      renderCell: (params) =>
        moment(params?.createdAt || params?.row.createdAt).format(
          "MM/DD/YYYY HH:mm:ss"
        ),
    },
    {
      access: ["Admin", "Tour Manager"],
      field: "createdBy.full_name",
      headerName: "Created By",
      width: 150,
      renderCell: ({ row }) => row?.createdBy?.full_name || "",
    },
    {
      access: ["Admin", "Tour Manager"],
      field: "updatedAt",
      headerName: "Modified Date",
      width: 200,
      renderCell: (params) =>
        moment(params?.updatedAt || params?.row.updatedAt).format(
          "MM/DD/YYYY HH:mm:ss"
        ),
    },
    {
      access: ["Admin", "Tour Manager"],
      field: "updatedBy.full_name",
      headerName: "Modified By",
      width: 150,
      renderCell: ({ row }) => row?.updatedBy?.full_name || "",
    },
    {
      headerName: "Actions",
      field: "actions",
      cellClassName: "actions",
      type: "actions",
      access: ["Admin", "Supplier", "Tour Manager"],
      width: 200,
      getActions: (params) =>
        [
          <GridActionsCellItem
            onClick={() =>
              redirectToPreviewVoucher(params?.row?.id || params?.id)
            }
            className="test1"
            icon={<TbTableExport size={21} style={{ fill: "none" }} />}
            label="Edit"
            title="Vouchers"
            access={["Admin", "Supplier", "Tour Manager"]}
            width={50}
            // disabled={
            //   (params?.state === "Pending" ||
            //     params?.row?.state === "Pending") &&
            //   role === "Supplier"
            // }
            // style={
            //   (params?.state === "Pending" ||
            //     params?.row?.state === "Pending") &&
            //   role === "Supplier"
            //     ? {
            //         cursor: "auto",
            //         opacity: 0.4,
            //       }
            //     : {
            //         cursor: "pointer",
            //       }
            // }
          />,
          <GridActionsCellItem
            onClick={() => handleEditPickUpPoint(params?.row)}
            icon={<MdOutlineEditLocation size={19} />}
            label="Edit Pick-Up Point"
            title="Edit Pick-Up Point"
            className="test1"
            access={["Supplier"]}
            // disabled={
            //   (params?.state === "Pending" ||
            //     params?.row?.state === "Pending") &&
            //   role === "Supplier"
            // }
            // style={
            //   (params?.state === "Pending" ||
            //     params?.row?.state === "Pending") &&
            //   role === "Supplier"
            //     ? {
            //         cursor: "auto",
            //         opacity: 0.4,
            //       }
            //     : {
            //         cursor: "pointer",
            //       }
            // }
          />,
          <GridActionsCellItem
            onClick={() => handleOpenDriverModal(params?.row)}
            icon={<MdMyLocation size={19} />}
            label="Edit Driver Location Url"
            title="Edit Driver Location Url"
            className="test1"
            access={["Supplier"]}
            // disabled={
            //   (params?.state === "Pending" ||
            //     params?.row?.state === "Pending") &&
            //   role === "Supplier"
            // }
            // style={
            //   (params?.state === "Pending" ||
            //     params?.row?.state === "Pending") &&
            //   role === "Supplier"
            //     ? {
            //         cursor: "auto",
            //         opacity: 0.4,
            //       }
            //     : {
            //         cursor: "pointer",
            //       }
            // }
          />,
          <GridActionsCellItem
            onClick={() =>
              redirectToPreview(params?.row?.id || params?.id, "tours")
            }
            className="test1"
            icon={<MdEdit />}
            label="Edit"
            title="View/Edit"
            access={["Admin", "Tour Manager"]}
            width={50}
          />,

          <GridActionsCellItem
            width={50}
            label="Edit"
            className="test1"
            title="Send to Supplier"
            icon={<BsFillSendFill />}
            access={["Admin", "Tour Manager"]}
            onClick={() => handleOpenSendEmailToSupplier(params?.row || params)}
            disabled={
              params?.state === "Pending" ||
              params?.row?.state === "Pending" ||
              params?.state === "Rejected" ||
              params?.row?.state === "Rejected" ||
              params?.state === "Accepted" ||
              params?.row?.state === "Accepted"
            }
            style={
              params?.state === "Pending" ||
              params?.row?.state === "Pending" ||
              params?.state === "Rejected" ||
              params?.row?.state === "Rejected" ||
              params?.state === "Accepted" ||
              params?.row?.state === "Accepted"
                ? {
                    cursor: "auto",
                    opacity: 0.4,
                  }
                : {
                    cursor: "pointer",
                  }
            }
          />,

          // <GridActionsCellItem
          //   style={
          //     // params.status || params.row.status?
          //     {
          //       cursor: "pointer",
          //     }
          //     // : {
          //     //     cursor: 'auto',
          //     //     opacity: 0.4,
          //     //   }
          //   }
          //   onClick={(ev) => handleUpdateStatus(params?.row || params)}
          //   icon={
          //     params?.status === "Active" ||
          //     params?.row?.status === "Active" ? (
          //       <FaToggleOn />
          //     ) : (
          //       <FaToggleOff />
          //     )
          //   }
          //   label="Status"
          //   title="Change Status"
          //   access={["Admin", "Tour Manager"]}
          //   width={50}
          // />,
          <GridActionsCellItem
            icon={<FaHistory />}
            label="State"
            title="Change Status"
            onClick={(ev) => {
              ev.stopPropagation();
              handleState(ev, params);
            }}
            access={["Admin", "Tour Manager", "Supplier"]}
          />,
          <GridActionsCellItem
            icon={<MdDelete />}
            label="Delete"
            title="Delete"
            onClick={(ev) => handleDelete(ev, params?.row || params)}
            access={["Admin", "Tour Manager"]}
            width={50}
          />,
        ].filter(({ props }) => {
          return props.access.includes(role);
        }),
    },
  ].filter((r) => r.access.includes(role));
};
