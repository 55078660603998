export const GET_ALL_FACILITIES_REQUEST = "GET_ALL_FACILITIES_REQUEST";
export const GET_ALL_FACILITIES_SUCCESS = "GET_ALL_FACILITIES_SUCCESS";
export const GET_ALL_FACILITIES_FAIL = "GET_ALL_FACILITIES_FAIL";

export function getAllFacilities() {
  return {
    type: GET_ALL_FACILITIES_REQUEST,
  };
}

export const GET_ALL_ROOM_FACILITIES_REQUEST =
  "GET_ALL_ROOM_FACILITIES_REQUEST";
export const GET_ALL_ROOM_FACILITIES_SUCCESS =
  "GET_ALL_ROOM_FACILITIES_SUCCESS";
export const GET_ALL_ROOM_FACILITIES_FAIL = "GET_ALL_ROOM_FACILITIES_FAIL";

export function getAllRoomFacilities() {
  return {
    type: GET_ALL_ROOM_FACILITIES_REQUEST,
  };
}
