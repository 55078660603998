import React from "react";
import { Button, Modal } from "react-bootstrap";

function UpdateFeaturedTypeModal({
  type,
  title,
  showUpdate,
  activeText,
  handleClose,
  deActiveText,
  handleConfirmUpdate,
}) {
  return (
    <Modal
      size="lg"
      show={showUpdate}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="modal_header" closeButton>
        <Modal.Title className="modal_title">Update {title} Type</Modal.Title>
      </Modal.Header>
      <div className="form-block">
        <Modal.Body>
          <div className="deleteConfirmText">
            {type === null && type !== "Featured" ? activeText : deActiveText}
          </div>
        </Modal.Body>
      </div>
      <Modal.Footer>
        <div className="previewButtons">
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmUpdate}>
            Confirm
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default UpdateFeaturedTypeModal;
