export const GET_ALL_TOURS_REQUEST = "GET_ALL_TOURS_REQUEST";
export const GET_ALL_TOURS_SUCCESS = "GET_ALL_TOURS_SUCCESS";
export const GET_ALL_TOURS_FAIL = "GET_ALL_TOURS_FAIL";

export function getAllToursAction(cb) {
  return {
    type: GET_ALL_TOURS_REQUEST,
    payload: {
      cb,
    },
  };
}

export const GET_SELECT_TOURS_REQUEST = "GET_SELECT_TOURS_REQUEST";
export const GET_SELECT_TOURS_SUCCESS = "GET_SELECT_TOURS_SUCCESS";
export const GET_SELECT_TOURS_FAIL = "GET_SELECT_TOURS_FAIL";

export function getSelectTours(service_date, direction_id, cb) {
  return {
    type: GET_SELECT_TOURS_REQUEST,
    payload: {
      service_date,
      direction_id,
      cb,
    },
  };
}

export const GET_TOURS_REQUEST = "GET_TOURS_REQUEST";
export const GET_TOURS_SUCCESS = "GET_TOURS_SUCCESS";
export const GET_TOURS_FAIL = "GET_TOURS_FAIL";

export function getToursAction(searchConfig, cb) {
  return {
    type: GET_TOURS_REQUEST,
    payload: {
      searchConfig,
      cb,
    },
  };
}

export const GET_SUPPLIER_TOURS_REQUEST = "GET_SUPPLIER_TOURS_REQUEST";
export const GET_SUPPLIER_TOURS_SUCCESS = "GET_SUPPLIER_TOURS_SUCCESS";
export const GET_SUPPLIER_TOURS_FAIL = "GET_SUPPLIER_TOURS_FAIL";

export function getSupplierToursAction(searchConfig, cb) {
  return {
    type: GET_SUPPLIER_TOURS_REQUEST,
    payload: {
      searchConfig,
      cb,
    },
  };
}

export const ADD_TOUR_REQUEST = "ADD_TOUR_REQUEST";
export const ADD_TOUR_SUCCESS = "ADD_TOUR_SUCCESS";
export const ADD_TOUR_FAIL = "ADD_TOUR_FAIL";

export function addTourAction(formData, cb) {
  return {
    type: ADD_TOUR_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const DELETE_TOUR_REQUEST = "DELETE_TOUR_REQUEST";
export const DELETE_TOUR_SUCCESS = "DELETE_TOUR_SUCCESS";
export const DELETE_TOUR_FAIL = "DELETE_TOUR_FAIL";

export function deleteTour(id, cb) {
  return {
    type: DELETE_TOUR_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const DELETE_SELECTED_TOURS_REQUEST = "DELETE_SELECTED_TOURS_REQUEST";
export const DELETE_SELECTED_TOURS_SUCCESS = "DELETE_SELECTED_TOURS_SUCCESS";
export const DELETE_SELECTED_TOURS_FAIL = "DELETE_SELECTED_TOURS_FAIL";

export function deleteSelectedTours(selectedRows, cb) {
  return {
    type: DELETE_SELECTED_TOURS_REQUEST,
    payload: {
      selectedRows,
      cb,
    },
  };
}

export const UPDATE_TOUR_REQUEST = "UPDATE_TOUR_REQUEST";
export const UPDATE_TOUR_SUCCESS = "UPDATE_TOUR_SUCCESS";
export const UPDATE_TOUR_FAIL = "UPDATE_TOUR_FAIL";

export function updateTour(formData, cb) {
  return {
    type: UPDATE_TOUR_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const UPDATE_TOUR_STATE_REQUEST = "UPDATE_TOUR_STATE_REQUEST";
export const UPDATE_TOUR_STATE_SUCCESS = "UPDATE_TOUR_STATE_SUCCESS";
export const UPDATE_TOUR_STATE_FAIL = "UPDATE_TOUR_STATE_FAIL";

export function updateTourState(formData, cb) {
  return {
    type: UPDATE_TOUR_STATE_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const UPDATE_TOUR_PICKUP_REQUEST = "UPDATE_TOUR_PICKUP_REQUEST";
export const UPDATE_TOUR_PICKUP_SUCCESS = "UPDATE_TOUR_PICKUP_SUCCESS";
export const UPDATE_TOUR_PICKUP_FAIL = "UPDATE_TOUR_PICKUP_FAIL";

export function updateTourPickUpId(formData, cb) {
  return {
    type: UPDATE_TOUR_PICKUP_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const STATUS_TOUR_REQUEST = "STATUS_TOUR_REQUEST";
export const STATUS_TOUR_SUCCESS = "STATUS_TOUR_SUCCESS";
export const STATUS_TOUR_FAIL = "STATUS_TOUR_FAIL";

export function changeTourStatusAction(id, cb) {
  return {
    type: STATUS_TOUR_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const GET_SINGLE_TOUR_REQUEST = "GET_SINGLE_TOUR_REQUEST";
export const GET_SINGLE_TOUR_SUCCESS = "GET_SINGLE_TOUR_SUCCESS";
export const GET_SINGLE_TOUR_FAIL = "GET_SINGLE_TOUR_FAIL";

export function getSingleTour(id, cb) {
  return {
    type: GET_SINGLE_TOUR_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const EXPORT_TOURS_REQUEST = "EXPORT_TOURS_REQUEST";
export const EXPORT_TOURS_SUCCESS = "EXPORT_TOURS_SUCCESS";
export const EXPORT_TOURS_FAIL = "EXPORT_TOURS_FAIL";

export function exportExcelToursAction(searchConfig, cb) {
  return {
    type: EXPORT_TOURS_REQUEST,
    payload: { searchConfig, cb },
  };
}

export const SEND_EMAIL_TO_SUPPLIER_REQUEST = "SEND_EMAIL_TO_SUPPLIER_REQUEST";
export const SEND_EMAIL_TO_SUPPLIER_SUCCESS = "SEND_EMAIL_TO_SUPPLIER_SUCCESS";
export const SEND_EMAIL_TO_SUPPLIER_FAIL = "SEND_EMAIL_TO_SUPPLIER_FAIL";

export function sendEmailToSupplier(formData, cb) {
  return {
    type: SEND_EMAIL_TO_SUPPLIER_REQUEST,
    payload: { formData, cb },
  };
}
