import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { blockPageAction } from "../../../store/actions/users";
import Select from "react-select";
import { getAllCurrency } from "../../../store/actions/currency";
import { getAllFacilities } from "../../../store/actions/facilities";
import { isEmpty } from "lodash";
import { react_icons } from "../Facilities";
import { City, Country, State } from "country-state-city";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import { TextField } from "@mui/material";

function WebApartmentsForm({ submit, footer, formData }) {
  const {
    reset,
    watch,
    control,
    trigger,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isDirty, isSubmitted },
  } = useForm();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [countries, setCountries] = useState([]);
  const [filteredStates, setFilteredStates] = useState([]);
  const [filteredCities, setFilteredCities] = useState([]);
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const [facilitiesOptions, setFacilitiesOptions] = useState([]);
  const [selectedFacilities, setSelectedFacilities] = useState([]);
  const allCurrency = useSelector((store) => store.currency.allCurrency);
  const allFacilities = useSelector((store) => store.facilities.allFacilities);

  useEffect(() => {
    dispatch(getAllCurrency());
    dispatch(getAllFacilities());
  }, []);

  useEffect(() => {
    if (id && formData) {
      reset(formData);
    }
  }, [formData]);

  useEffect(() => {
    if (id && formData) {
      reset(formData);
      if (!isEmpty(formData) && formData.state_code && formData.city) {
        getStates(formData.country_code);
        getCities(formData.state_code);
      }
    }
  }, [formData]);

  useEffect(() => {
    if (allFacilities) {
      const updatedOptions = allFacilities?.map((facility) => {
        return {
          ...facility,
        };
      });
      setFacilitiesOptions(updatedOptions);
    }
  }, [allFacilities]);

  useEffect(() => {
    if (formData?.facilities && formData?.facilities.length > 0) {
      const initialSelectedFacilities = formData?.facilities.map((i) => {
        const facility = i.facilityId || i;
        return {
          ...facility,
        };
      });
      setSelectedFacilities(initialSelectedFacilities);
    }
  }, [formData?.facilities]);

  useEffect(() => {
    const allCountries = Country.getAllCountries();
    const countriesWithStates = allCountries.filter((country) => {
      const states = State.getStatesOfCountry(country.isoCode);
      const excludedCountries = ["AZ", "TR", "BM", "HK", "MH", "MC", "XK"];
      return states.length > 0 && !excludedCountries.includes(country.isoCode);
    });
    setCountries(countriesWithStates);
  }, []);

  const getStates = (isoCode) => {
    let states = State.getStatesOfCountry(isoCode);
    const statesWithCities = states.filter((state) => {
      const cities = City.getCitiesOfState(isoCode, state.isoCode);
      return cities.length > 0;
    });
    setFilteredStates(statesWithCities);
  };

  const getCities = (isoCode) => {
    const countryCode = watch("country_code");
    if (countryCode === "LK" && isoCode === "1") {
      setFilteredCities([
        ...City.getCitiesOfState(countryCode, isoCode),
        {
          name: "Weweldeniya",
          countryCode: "LK",
          stateCode: "1",
          latitude: "7.25360000",
          longitude: "80.17610000",
        },
      ]);
    } else if (countryCode === "GR" && isoCode === "M") {
      setFilteredCities([
        ...City.getCitiesOfState(countryCode, isoCode),
        {
          name: "Heraklion",
          countryCode: "GR",
          stateCode: "M",
          latitude: "35.3403",
          longitude: "25.1442",
        },
        {
          name: "Knossos",
          countryCode: "GR",
          stateCode: "M",
          latitude: "35.2980",
          longitude: "25.1300",
        },
        {
          name: "Loutro",
          countryCode: "GR",
          stateCode: "M",
          latitude: "35.1866",
          longitude: "23.6342",
        },
        {
          name: "Lerapetra",
          countryCode: "GR",
          stateCode: "M",
          latitude: "35.0399",
          longitude: "25.7790",
        },
        {
          name: "Agia Paraskevi",
          countryCode: "GR",
          stateCode: "M",
          latitude: "38.01667",
          longitude: "23.83333",
        },
      ]);
    } else {
      setFilteredCities([...City.getCitiesOfState(countryCode, isoCode)]);
    }
  };

  useEffect(() => {
    if (id && formData) {
      if (isDirty) {
        setIsFormDisabled(false);
        dispatch(blockPageAction(`/website-management/apartments/${id}`));
      } else {
        setIsFormDisabled(true);
        dispatch(blockPageAction(""));
      }
    }
  }, [isDirty]);

  const onSubmit = (data) => {
    submit(data);
  };

  const getCustomNotRequiredStyles = (field) => {
    const isInvalid = errors[field];
    const isValid = isSubmitted && !isInvalid;
    return {
      control: (styles) => ({
        ...styles,
        borderColor: isInvalid ? "#198754" : isValid ? "#198754" : "#ced4da",
      }),
      option: (styles) => ({
        ...styles,
        ":active": {
          ...styles[":active"],
          backgroundColor: `var(--primary_light)`,
        },
      }),
    };
  };

  const getCustomStyles = (field) => {
    const isInvalid = errors[field];
    const isValid = isSubmitted && !isInvalid;
    return {
      control: (styles) => ({
        ...styles,
        borderColor: isInvalid ? "#dc3545" : isValid ? "#198754" : "#ced4da",
      }),
      option: (styles) => ({
        ...styles,
        ":active": {
          ...styles[":active"],
          backgroundColor: `var(--primary_light)`,
        },
      }),
    };
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="form-block">
          <Modal.Body>
            <Row>
              <Col sm={12} lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Apartment Name
                    <span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Form.Control
                    id="apartment_name"
                    name="apartment_name"
                    maxLength={254}
                    isValid={isSubmitted && !errors.apartment_name}
                    isInvalid={isSubmitted && !!errors.apartment_name}
                    {...register("apartment_name", {
                      required: "Required Field",
                      pattern: {
                        value: /^(?!\s*$).+/,
                        message: "Only spaces are not allowed",
                      },
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.apartment_name && errors.apartment_name.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={12} lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Price (€)
                    <span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Form.Control
                    id="price"
                    name="price"
                    isInvalid={isSubmitted && !watch("price")}
                    isValid={isSubmitted && watch("price")}
                    {...register("price", { required: true })}
                  />
                  <Form.Control.Feedback type="invalid">
                    Required Field
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              {/*<Col sm={12} lg={2}>*/}
              {/*  <Form.Group className="mb-3">*/}
              {/*    <Form.Label>Currency</Form.Label>*/}
              {/*    <Select*/}
              {/*      name="currency_id"*/}
              {/*      id="web_suite_currency"*/}
              {/*      className="react-currency-select"*/}
              {/*      classNamePrefix="react-currency-select"*/}
              {/*      styles={*/}
              {/*        errors.currency_id && !watch("currency_id")*/}
              {/*          ? getCustomStyles("currency_id")*/}
              {/*          : getCustomNotRequiredStyles("currency_id")*/}
              {/*      }*/}
              {/*      placeholder="€"*/}
              {/*      options={allCurrency}*/}
              {/*      getOptionLabel={(i) =>*/}
              {/*        `${i.currency_name}(${i.currency_symbol})`*/}
              {/*      }*/}
              {/*      {...register("currency_id", {*/}
              {/*        required: watch("price") && !watch("currency_id"),*/}
              {/*      })}*/}
              {/*      getOptionValue={(i) => i.id}*/}
              {/*      value={*/}
              {/*        watch("currency_id") && Array.isArray(allCurrency)*/}
              {/*          ? allCurrency.find((i) => i.id === watch("currency_id"))*/}
              {/*          : []*/}
              {/*      }*/}
              {/*      isClearable={true}*/}
              {/*      onChange={(ev) => {*/}
              {/*        setValue("currency_id", ev ? ev.id : null, {*/}
              {/*          shouldDirty: true,*/}
              {/*        });*/}
              {/*      }}*/}
              {/*    />*/}
              {/*  </Form.Group>*/}
              {/*</Col>*/}
              <Col sm={12} lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Phone
                    <span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Form.Control
                    id="phone"
                    name="phone"
                    maxLength={254}
                    isInvalid={isSubmitted && !watch("phone")}
                    isValid={isSubmitted && watch("phone")}
                    {...register("phone", { required: true })}
                  />
                  <Form.Control.Feedback type="invalid">
                    Required Field
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Country
                    <span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Controller
                    name="country_code"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder={"Your Country"}
                        classNamePrefix="react-select"
                        options={countries}
                        styles={
                          errors.country_code && !watch("country_code")
                            ? getCustomStyles("country_code")
                            : getCustomNotRequiredStyles("country_code")
                        }
                        getOptionLabel={(countries) => countries.name}
                        getOptionValue={(countries) => countries.isoCode}
                        {...register("country_code", {
                          required: "Required Field",
                        })}
                        value={
                          (countries &&
                            countries?.find(
                              (country) =>
                                country.isoCode === watch("country_code")
                            )) ||
                          null
                        }
                        onChange={(ev) => {
                          field.onChange(ev.isoCode);
                          getStates(ev.isoCode);
                          setValue("country", ev.name);
                          setValue("country_code", ev.isoCode);
                          setValue("state_code", null);
                          setValue("state", null);
                          setValue("city", null);
                          trigger("country_code");
                        }}
                      />
                    )}
                  />
                  {errors.country_code ? (
                    <div className="invalid_message">
                      {errors.country_code && errors.country_code.message}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    State
                    <span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Controller
                    name="state_code"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder={"Your State"}
                        classNamePrefix="react-select"
                        options={filteredStates}
                        styles={
                          errors.state_code && !watch("state_code")
                            ? getCustomStyles("state_code")
                            : getCustomNotRequiredStyles("state_code")
                        }
                        getOptionLabel={(filteredStates) => filteredStates.name}
                        getOptionValue={(filteredStates) =>
                          filteredStates.isoCode
                        }
                        {...register("state_code", {
                          required: "Required Field",
                        })}
                        value={
                          filteredStates &&
                          !!watch("state_code") &&
                          filteredStates?.find(
                            (state) => state.isoCode == watch("state_code")
                          )
                        }
                        onChange={(ev) => {
                          field.onChange(ev.isoCode);
                          getCities(ev.isoCode);
                          setValue("state_code", ev.isoCode);
                          setValue("state", ev.name);
                          setValue("city", null);
                        }}
                      />
                    )}
                  />
                  {errors.state_code ? (
                    <div className="invalid_message">
                      {errors.state_code && errors.state_code.message}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    City
                    <span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Controller
                    name="city"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder={"Your City"}
                        classNamePrefix="react-select"
                        options={filteredCities}
                        styles={
                          errors.city && !watch("city")
                            ? getCustomStyles("city")
                            : getCustomNotRequiredStyles("city")
                        }
                        getOptionLabel={(filteredCities) => filteredCities.name}
                        getOptionValue={(filteredCities) => filteredCities.name}
                        {...register("city", { required: "Required Field" })}
                        value={
                          filteredCities &&
                          !!watch("city") &&
                          filteredCities?.find(
                            (city) => city.name === watch("city")
                          )
                        }
                        onChange={(ev) => {
                          field.onChange(ev.name);
                        }}
                      />
                    )}
                  />
                  {errors.city ? (
                    <div className="invalid_message">
                      {errors.city && errors.city.message}
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Address
                    <span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Form.Control
                    id="address"
                    name="address"
                    maxLength={254}
                    isValid={isSubmitted && !errors.address}
                    isInvalid={isSubmitted && !!errors.address}
                    {...register("address", {
                      required: "Required Field",
                      pattern: {
                        value: /^(?!\s*$).+/,
                        message: "Only spaces are not allowed",
                      },
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.address && errors.address.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={2}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Zip Code
                    <span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Form.Control
                    id="zip_code"
                    name="zip_code"
                    maxLength={254}
                    isValid={isSubmitted && !errors.zip_code}
                    isInvalid={isSubmitted && !!errors.zip_code}
                    {...register("zip_code", {
                      required: "Required Field",
                      pattern: {
                        value: /^(?!\s*$).+/,
                        message: "Only spaces are not allowed",
                      },
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.zip_code && errors.zip_code.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Check In</Form.Label>
                  <Form.Control
                    type="time"
                    name={"check_in"}
                    max={watch("check_in")}
                    id={"tour_check_in"}
                    value={watch("check_in") || null}
                    {...register("check_in")}
                  />
                </Form.Group>
              </Col>
              <Col sm={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Check Out</Form.Label>
                  <Form.Control
                    type="time"
                    name={"check_out"}
                    max={watch("check_out")}
                    value={watch("check_out") || null}
                    id={"tour_check_out"}
                    {...register("check_out")}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    rows={4}
                    as="textarea"
                    id="description"
                    name="description"
                    isValid={isSubmitted && !watch("description")}
                    {...register("description")}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Facilities
                    <span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Controller
                    control={control}
                    name="facilities"
                    rules={{
                      required: "At least one facility must be selected",
                      validate: (value) =>
                        value.length > 0 ||
                        "At least one facility must be selected",
                    }}
                    render={({ field, fieldState }) => (
                      <>
                        <div
                          id="hotel_facilities"
                          className={"gridContainerStyle"}
                        >
                          {facilitiesOptions?.map((facility) => (
                            <Form.Check.Label
                              htmlFor={`facility-${facility.id}`}
                              className={"facilityCardStyle"}
                            >
                              <Form.Check
                                key={facility.id}
                                type="checkbox"
                                id={`facility-${facility.id}`}
                                checked={selectedFacilities.some(
                                  (sf) => sf.id === facility.id
                                )}
                                onChange={() => {
                                  let updatedSelectedFacilities;
                                  if (
                                    selectedFacilities.some(
                                      (sf) => sf.id === facility.id
                                    )
                                  ) {
                                    updatedSelectedFacilities =
                                      selectedFacilities.filter(
                                        (sf) => sf.id !== facility.id
                                      );
                                  } else {
                                    updatedSelectedFacilities = [
                                      ...selectedFacilities,
                                      {
                                        id: facility.id,
                                        facility_name: facility.facility_name,
                                        facilityId: {
                                          facility_name: facility.facility_name,
                                        },
                                      },
                                    ];
                                  }
                                  setSelectedFacilities(
                                    updatedSelectedFacilities
                                  );
                                  field.onChange(updatedSelectedFacilities);
                                  trigger("facilities");
                                }}
                              />

                              {facility.facility_name}
                            </Form.Check.Label>
                          ))}
                        </div>
                        {fieldState?.error && (
                          <div className="required_message">
                            {fieldState?.error?.message}
                          </div>
                        )}
                      </>
                    )}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
        </div>
        <Modal.Footer>
          {React.cloneElement(footer, { disabled: isFormDisabled })}
        </Modal.Footer>
      </Form>
    </>
  );
}

export default WebApartmentsForm;
