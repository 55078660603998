import { GridActionsCellItem } from "@mui/x-data-grid";
import { MdDelete, MdEdit } from "react-icons/md";
import { FaToggleOff, FaToggleOn } from "react-icons/fa";
import React from "react";
import moment from "moment";

export const getColumns = ({
  handleUpdateStatus,
  redirectToPreview,
  handleDelete,
}) => {
  return [
    {
      field: "id",
      headerName: "#",
      width: 70,
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
    },
    {
      field: "start_date",
      headerName: "Start Date",
      width: 200,
      renderCell: (params) =>
        moment(params?.start_date || params?.row.start_date).format(
          "MM/DD/YYYY"
        ),
    },
    {
      field: "end_date",
      headerName: "End Date",
      width: 200,
      renderCell: (params) =>
        moment(params?.end_date || params?.row.end_date).format("MM/DD/YYYY"),
    },
    {
      field: "createdAt",
      headerName: "Created Date",
      width: 200,
      renderCell: (params) =>
        moment(params?.createdAt || params?.row.createdAt).format(
          "MM/DD/YYYY HH:mm:ss"
        ),
    },
    {
      field: "createdBy.full_name",
      headerName: "Created By",
      width: 150,
      renderCell: ({ row }) => row?.createdBy?.full_name || "",
    },
    {
      field: "updatedAt",
      headerName: "Modified Date",
      width: 200,
      renderCell: (params) =>
        moment(params?.updatedAt || params?.row.updatedAt).format(
          "MM/DD/YYYY HH:mm:ss"
        ),
    },
    {
      field: "updatedBy.full_name",
      headerName: "Modified By",
      width: 150,
      renderCell: ({ row }) => row?.updatedBy?.full_name || "",
    },
    {
      headerName: "Actions",
      field: "actions",
      width: 200,
      cellClassName: "actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          onClick={() => redirectToPreview(params.row?.id || params?.id)}
          className="test1"
          icon={<MdEdit />}
          label="Edit"
          title="View/Edit"
        />,
        <GridActionsCellItem
          style={
            // params.status || params.row.status?
            {
              cursor: "pointer",
            }
            // : {
            //     cursor: 'auto',
            //     opacity: 0.4,
            //   }
          }
          onClick={(ev) => handleUpdateStatus(params?.row || params)}
          icon={
            params?.status === "Active" || params?.row?.status === "Active" ? (
              <FaToggleOn />
            ) : (
              <FaToggleOff />
            )
          }
          label="Status"
          title="Change Status"
        />,
        <GridActionsCellItem
          icon={<MdDelete />}
          label="Delete"
          title="Delete"
          onClick={(ev) => handleDelete(ev, params.row || params)}
        />,
      ],
    },
  ];
};
