import React from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import { useForm } from "react-hook-form";

function SendEmailToSupplierModal({
  show,
  tour,
  title,
  confirm,
  details,
  disabled,
  handleClose,
  actionStatus,
}) {
  const { reset, register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    if (data?.text_to_supplier) {
      data.text_to_supplier = data.text_to_supplier.trim();
    }
    let mailData = { ...data, tourId: tour?.id };
    confirm(mailData);
    setTimeout(() => {
      reset();
    }, 2000);
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="modal_header" closeButton>
        <Modal.Title className="modal_title">{title}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="form-block">
            <div className="modal_content">
              <div className="deleteConfirmText">
                You are about to send an email to{" "}
                <strong>{tour?.supplierUser?.full_name}</strong>
                {tour?.supplierUser?.email ? (
                  <strong>({tour?.supplierUser?.email})</strong>
                ) : null}
                to review and accept the following tour.
              </div>
              {details && (
                <div className="deleteConfirmText">
                  <p className="mb-0">
                    <strong>Tour Name:</strong> {tour?.tour_name}
                  </p>
                  {tour?.service_date && (
                    <p className="mb-0">
                      <strong>Tour Date:</strong>{" "}
                      {moment(tour?.service_date).format("MM/DD/YYYY")}
                    </p>
                  )}
                  <p className="mb-0">
                    <strong>Direction:</strong>{" "}
                    {tour?.direction?.direction_name}
                  </p>
                  {tour?.total_passengers && (
                    <p className="mb-0">
                      <strong>Total Passengers:</strong>{" "}
                      {tour?.total_passengers}
                    </p>
                  )}
                </div>
              )}
              {details && (
                <Col sm={12}>
                  <Form.Group className={"mb-3"}>
                    <Form.Label>
                      <strong style={{ color: "#303030" }}>Notes</strong>
                    </Form.Label>
                    <Form.Control
                      rows={3}
                      as="textarea"
                      maxLength={2000}
                      id="text_to_supplier"
                      name="text_to_supplier"
                      className="text_area_input"
                      {...register("text_to_supplier")}
                    />
                  </Form.Group>
                </Col>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="previewButtons">
            <LoadingButton
              type="submit"
              size="small"
              className="login_submit_btn btn btn-primary "
              loadingPosition="center"
              variant="contained"
              loading={actionStatus === "pending"}
              disabled={disabled}
            >
              <span>{actionStatus === "pending" ? "" : "Confirm"}</span>
            </LoadingButton>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default SendEmailToSupplierModal;
