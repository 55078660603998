import { api } from "../Api";

export default class Facilities {
  static getAllFacilities() {
    return api.get(`/admin/web/hotels/facilities/all`);
  }

  static getAllRoomFacilities() {
    return api.get(`/admin/web/hotels/room_facilities/all`);
  }
}
