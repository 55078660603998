import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Table from "../../../../components/table";
import Pagination from "../../../../components/pagination/Pagination";
import SearchFilterBar from "../../../../components/search_filter_bar/SearchFilterBar";
import TableTitle from "../../../../components/table_title/TableTitle";
import { setPage, sortAction } from "../../../../store/actions/search";
import DeleteModal from "../../../../components/modals/DeleteModal2";
import { getColumns } from "./column";
import ApartmentRoomsForm from "./ApartmentRoomsForm";
import { isEmpty } from "lodash";
import UpdateStatusModal from "../../../../components/modals/UpdateStatusModal";
import MobileLayout from "../../../../components/mobileLayout/MobileLayout";
import { website_rooms_mobile_column } from "../../../../staticVariables/mobileColumns";
import DynamicModal from "../../../../components/modals/DynamicModal";
import ModalButtons from "../../../../components/modals/modalButtons/ModalButtons";
import {
  getApartmentRoomAction,
  addRoomAction,
  deleteApartmentRoom,
  changeApartmentRoomStatusAction,
} from "../../../../store/actions/websiteApartments";

export const hotelStatus = [
  {
    id: "Active",
    color: "green",
  },
  {
    id: "Inactive",
    color: "#dc3545",
  },
];

function ApartmentRooms() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [rowId, setRowId] = useState("");
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  let searchConfig = useSelector((store) => store.search);
  const account = useSelector((store) => store.users.account);
  let rows = useSelector((store) => store?.websiteApartments?.rooms);
  const count = useSelector((store) => store.websiteApartments.count);
  let roomName = useSelector((store) => store.websiteApartments.roomName);
  let rowStatus = useSelector((store) => store?.websiteApartments?.status);
  let actionStatus = useSelector(
    (store) => store?.websiteApartments?.actionStatus
  );

  useEffect(() => {
    if (id) {
      getApartmentRoomsList(id, searchConfig);
    }
  }, [id]);

  const redirectToPreview = useCallback((params) => {
    navigate(`/website-management/website-apartments/rooms/${id}/${params}`);
  }, []);

  const handleNavigateGallery = (roomId) => {
    navigate(
      `/website-management/website-apartments/rooms/gallery/${id}/${roomId}`
    );
  };

  const handleClose = () => {
    setShow(false);
    setShowDelete(false);
    setShowStatus(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  const handleSort = (model) => {
    if (model.length) {
      searchConfig.sort = model;
      dispatch(sortAction(model));
    } else {
      model = searchConfig.sort;
    }
    getApartmentRoomsList(id, searchConfig);
  };

  const getApartmentRoomsList = (id, searchConfig) => {
    dispatch(getApartmentRoomAction(id, searchConfig));
  };

  const handleAddConfirm = async (data) => {
    dispatch(
      addRoomAction(id, data, (err, data) => {
        if (data) {
          toast.success("Successfully created.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          getApartmentRoomsList(id, searchConfig);
          handleClose();
        } else if (err) {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
        }
      })
    );
  };

  const handleUpdateStatus = (params) => {
    setRowId(params);
    setShowStatus(true);
  };

  const handleConfirmUpdateStatus = () => {
    dispatch(
      changeApartmentRoomStatusAction(rowId?.id, (err, data) => {
        if (data) {
          toast.success("Successfully updated.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          getApartmentRoomsList(id, searchConfig);
        } else if (err) {
          toast.error(err.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
        }
        setShowStatus(false);
      })
    );
  };

  const handleDelete = (ev, params) => {
    ev.stopPropagation();
    setRowId(params);
    setShowDelete(true);
  };

  const handleConfirmDelete = () => {
    dispatch(
      deleteApartmentRoom(rowId?.id, (err, data) => {
        if (data) {
          toast.success("Successfully deleted.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          if (rows && rows.length === 1 && searchConfig.page !== 1) {
            searchConfig.page = searchConfig.page - 1;
            dispatch(setPage(searchConfig.page));
          }
          setRowId(null);
          setShowDelete(false);
          getApartmentRoomsList(id, searchConfig);
        }
        if (err) {
          toast.error(err.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          handleClose();
        }
      })
    );
  };

  const columns = getColumns({
    rows,
    navigate,
    handleDelete,
    redirectToPreview,
    handleUpdateStatus,
    handleNavigateGallery,
    role: !isEmpty(account) ? account.role : "",
  });

  return (
    <div className="admin_tables_block">
      <TableTitle
        title={`Apartment Rooms / ${roomName ? roomName : ""}`}
        goBack={true}
        parentLink={`/website-management/website-apartments`}
        handleShow={handleShow}
        addNew={
          (account && account.role === "Admin") ||
          account.role === "Tour Manager"
        }
        rooms={true}
      />
      <SearchFilterBar
        getList={getApartmentRoomsList}
        columns={columns}
        setShowFilter={setShowFilter}
        showFilter={showFilter}
      />
      <Table
        status={rowStatus}
        rows={rows || []}
        columns={columns}
        setShow={setShow}
        setSort={handleSort}
        checkboxSelection={false}
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
      />
      <MobileLayout
        rows={rows || []}
        rowStatus={rowStatus}
        columns={columns}
        setShow={setShow}
        changeStatusHandler={handleConfirmUpdateStatus}
        parentTitle={"Website Apartment"}
        link="/website-management/website-apartments"
        mobileCol={website_rooms_mobile_column}
        status={hotelStatus}
        search={getApartmentRoomsList}
      />
      <DynamicModal
        show={show}
        hide={() => setShow(false)}
        title={"Add New Room"}
        body={
          <ApartmentRoomsForm
            submit={handleAddConfirm}
            footer={
              <ModalButtons
                submit={handleAddConfirm}
                cancel={() => setShow(false)}
                isLoading={actionStatus === "pending"}
              />
            }
          />
        }
      />
      <DeleteModal
        show={showDelete}
        handleClose={() => handleClose()}
        confirm={(e) => handleConfirmDelete(e, rowId)}
        content={`You are about to delete the #${rowId?.id} room. Are you sure?`}
        title={"Delete Apartment Room"}
        actionStatus={actionStatus}
      />
      <UpdateStatusModal
        show={showStatus}
        handleConfirmUpdate={handleConfirmUpdateStatus}
        handleClose={handleClose}
        text={`You're about to ${
          rowId?.status === "Active" ? "de-activate" : "activate"
        }  the ${rowId?.room_name} room. Are you sure?`}
        title={"Update Apartment Room"}
        actionStatus={actionStatus}
      />
      <Pagination
        rowStatus={rowStatus}
        count={count}
        limit={10}
        getList={getApartmentRoomsList}
      />
    </div>
  );
}

export default ApartmentRooms;
