import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Table from "../../../components/table";
import Pagination from "../../../components/pagination/Pagination";
import SearchFilterBar from "../../../components/search_filter_bar/SearchFilterBar";
import TableTitle from "../../../components/table_title/TableTitle";
import { setPage, sortAction } from "../../../store/actions/search";
import DeleteModal from "../../../components/modals/DeleteModal2";
import { getColumns } from "./column";
import {
  addVillaAction,
  deleteVilla,
  changeVillaStatusAction,
  exportExcelVillaAction,
  getVillasAction,
  getSingleVilla,
  updateSingleVillaType,
} from "../../../store/actions/websiteVillas";
import WebVillasForm from "./WebVillasForm";
import { isEmpty } from "lodash";
import UpdateStatusModal from "../../../components/modals/UpdateStatusModal";
import MobileLayout from "../../../components/mobileLayout/MobileLayout";
import { website_villas_mobile_column } from "../../../staticVariables/mobileColumns";
import DynamicModal from "../../../components/modals/DynamicModal";
import ModalButtons from "../../../components/modals/modalButtons/ModalButtons";
import UpdateFeaturedTypeModal from "../../../components/modals/UpdateFeaturedTypeModal";

export const villaStatus = [
  {
    id: "Active",
    color: "green",
  },
  {
    id: "Inactive",
    color: "#dc3545",
  },
];

function WebVillas() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [rowId, setRowId] = useState("");
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [singleVilla, setSingleVilla] = useState({});
  const [showFeaturedModal, setShowFeaturedModal] = useState(false);
  let searchConfig = useSelector((store) => store.search);
  const account = useSelector((store) => store.users.account);
  let rows = useSelector((store) => store?.websiteVillas?.villas);
  const count = useSelector((store) => store.websiteVillas.count);
  let rowStatus = useSelector((store) => store?.websiteVillas?.status);
  let actionStatus = useSelector((store) => store?.websiteVillas?.actionStatus);
  let exportExcelStatus = useSelector(
    (store) => store?.websiteVillas?.exportExcelStatus
  );

  useEffect(() => {
    getVillasList(searchConfig);
  }, []);

  const redirectToPreview = useCallback((params) => {
    navigate(`/website-management/website-villas/${params}`);
  }, []);

  const handleNavigateGallery = (id) => {
    navigate(`/website-management/website-villas/gallery/${id}`);
  };

  const handleNavigateToRooms = (id) => {
    navigate(`/website-management/website-villas/rooms/${id}`);
  };

  const handleClose = () => {
    setShow(false);
    setShowDelete(false);
    setShowStatus(false);
    setShowFeaturedModal(false);
  };

  const handleDelete = (ev, params) => {
    ev.stopPropagation();
    setRowId(params);
    setShowDelete(true);
  };

  const handleConfirmDelete = () => {
    dispatch(
      deleteVilla(rowId?.id, (err, data) => {
        if (data) {
          toast.success("Successfully deleted.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          if (rows && rows.length === 1 && searchConfig.page !== 1) {
            searchConfig.page = searchConfig.page - 1;
            dispatch(setPage(searchConfig.page));
          }
          setRowId(null);
          setShowDelete(false);
          getVillasList(searchConfig);
        }
        if (err) {
          toast.error(err.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          handleClose();
        }
      })
    );
  };

  const handleShow = () => {
    setShow(true);
  };

  const handleSort = (model) => {
    if (model.length) {
      searchConfig.sort = model;
      dispatch(sortAction(model));
    } else {
      model = searchConfig.sort;
    }
    getVillasList(searchConfig);
  };

  const getVillasList = (searchConfig) => {
    dispatch(getVillasAction(searchConfig));
  };

  const exportExcel = () => {
    dispatch(exportExcelVillaAction(searchConfig));
  };
  const handleAddConfirm = async (data) => {
    dispatch(
      addVillaAction(data, (err, data) => {
        if (data) {
          toast.success("Successfully created.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          dispatch(getVillasAction(searchConfig));
          handleClose();
        } else if (err) {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
        }
      })
    );
  };

  const handleUpdateStatus = (params) => {
    setRowId(params);
    setShowStatus(true);
  };

  const handleConfirmUpdateStatus = () => {
    dispatch(
      changeVillaStatusAction(rowId?.id, (err, data) => {
        if (data) {
          toast.success("Successfully updated.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          getVillasList(searchConfig);
        } else if (err) {
          toast.error(err.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
        }
        setShowStatus(false);
      })
    );
  };

  const handleShowFeaturedStatusModal = async (ev, id) => {
    ev.stopPropagation();
    setShowFeaturedModal(true);
    dispatch(
      getSingleVilla(id, (err, data) => {
        if (data && data?.villa) {
          setSingleVilla(data?.villa);
        }
      })
    );
  };

  const handleChangeFeaturedType = () => {
    let type;
    if (singleVilla?.type === "Featured") {
      type = null;
    } else {
      type = "Featured";
    }
    dispatch(
      updateSingleVillaType(singleVilla?.id, type, (err, data) => {
        if (!err) {
          setSingleVilla({});
          setShowFeaturedModal(false);
          toast.success("Successfully Updated", {
            autoClose: 3000,
          });
          getVillasList(searchConfig);
        }
      })
    );
  };

  const columns = getColumns({
    rows,
    navigate,
    handleDelete,
    redirectToPreview,
    handleUpdateStatus,
    handleNavigateGallery,
    handleNavigateToRooms,
    handleShowFeaturedStatusModal,
    role: !isEmpty(account) ? account.role : "",
  });

  return (
    <div className="admin_tables_block">
      <TableTitle
        title={"Website Villas"}
        handleShow={handleShow}
        addNew={
          (account && account.role === "Admin") ||
          account.role === "Tour Manager"
        }
        // exportExcel={
        //   (!isEmpty(account) && account.role === "Admin") ||
        //   account.role === "Tour Manager"
        //     ? exportExcel
        //     : null
        // }
        // exportExcelStatus={exportExcelStatus}
      />
      <SearchFilterBar
        getList={getVillasList}
        columns={columns}
        setShowFilter={setShowFilter}
        showFilter={showFilter}
      />
      <Table
        status={rowStatus}
        rows={rows || []}
        columns={columns}
        setShow={setShow}
        setSort={handleSort}
        checkboxSelection={false}
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
      />
      <MobileLayout
        rows={rows || []}
        rowStatus={rowStatus}
        columns={columns}
        setShow={setShow}
        changeStatusHandler={handleConfirmUpdateStatus}
        parentTitle={"Website Villas"}
        link="/website-management/website-villas"
        mobileCol={website_villas_mobile_column}
        status={villaStatus}
        search={getVillasList}
      />
      <DynamicModal
        show={show}
        hide={() => setShow(false)}
        title={"Add New Villa"}
        body={
          <WebVillasForm
            submit={handleAddConfirm}
            footer={
              <ModalButtons
                submit={handleAddConfirm}
                cancel={() => setShow(false)}
                isLoading={actionStatus === "pending"}
              />
            }
          />
        }
      />
      <DeleteModal
        show={showDelete}
        handleClose={() => handleClose()}
        confirm={(e) => handleConfirmDelete(e, rowId)}
        content={`You are about to delete the #${rowId?.id} villa. Are you sure?`}
        title={"Delete Website Villas"}
        actionStatus={actionStatus}
      />
      <UpdateStatusModal
        show={showStatus}
        handleConfirmUpdate={handleConfirmUpdateStatus}
        handleClose={handleClose}
        text={`You're about to ${
          rowId?.status === "Active" ? "de-activate" : "activate"
        }  the ${rowId?.villa_name} villa. Are you sure?`}
        title={"Update Website Villas"}
        actionStatus={actionStatus}
      />
      <UpdateFeaturedTypeModal
        title={"Villa"}
        handleClose={handleClose}
        type={singleVilla?.type}
        showUpdate={showFeaturedModal}
        handleConfirmUpdate={handleChangeFeaturedType}
        activeText={`You're about to mark  ${singleVilla?.villa_name}'s villa as featured. Are you sure?`}
        deActiveText={`You're about to mark ${singleVilla?.villa_name}'s villa as not featured. Are you sure?`}
      />
      <Pagination
        rowStatus={rowStatus}
        count={count}
        limit={10}
        getList={getVillasList}
      />
    </div>
  );
}

export default WebVillas;
