import { api } from "../Api";
import axios from "axios";
import Account from "../../services/Account";

const { REACT_APP_API_URL } = process.env;

export default class Tours {
  static addTours(formData) {
    return api.post(`/admin/web/tours`, formData);
  }

  static updateTours(formData) {
    return api.put(`/admin/web/tours/${formData.id}`, formData);
  }

  static deleteTours(id) {
    return api.delete(`/admin/web/tours/${id}`);
  }

  static getAllTours() {
    return api.get(`/admin/web/tours/all`);
  }

  static getSingleTours(id) {
    return api.get(`/admin/web/tours/${id}`);
  }

  static getTours(page, limit, search, filter, [{ sort, field }]) {
    return api.get(`/admin/web/tours`, {
      params: {
        search,
        filter,
        page,
        limit,
        sort,
        field,
      },
    });
  }

  static getSelectTours(service_date, direction_id) {
    return api.get(`/admin/web/tours/select`, {
      params: {
        service_date,
        direction_id,
      },
    });
  }

  static changeTourStatus(id) {
    return api.put(`/admin/web/tours/change/status/${id}`);
  }

  static updateTourPickUp(formData) {
    return api.put(`/admin/web/tours/pickup/${formData.id}`, formData);
  }

  static handleExportToursExcel(page, search, filter, [{ sort, field }]) {
    let service_date = {};
    if (filter.service_date) {
      if (filter.service_date["$gte"]) {
        service_date.startDate = filter.service_date["$gte"];
      }
      if (filter.service_date["$lte"]) {
        service_date.endDate = filter.service_date["$lte"];
      }
    }
    return api({
      url: `/admin/web/tours/excel`,
      method: "GET",
      responseType: "blob",
      params: {
        search,
        service_date,
        page,
        sort,
        field,
      },
    });
  }

  static getGallery(id) {
    return api.get(`/admin/web/tours/gallery/images/${id}`);
  }

  static updateGallery(id, formData) {
    const fd = new FormData();

    fd.append("main_image", formData?.main_image_file);
    if (formData.images && formData.images.length > 0) {
      formData.images.forEach((image) => {
        fd.append("images", image.file);
      });
    }
    fd.append("formData", JSON.stringify({ ...formData }));

    const res = axios.put(
      `${REACT_APP_API_URL}/admin/web/tours/gallery/images/${id}`,
      fd,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
          Authorization: Account.getToken() && `Bearer ${Account.getToken()}`,
        },
      }
    );

    return res;
  }

  static updateType(id) {
    return api.put(`/admin/web/featured/tours/${id}`);
  }

  static fetchWebToursFromBokun() {
    return api.get(`/admin/web/bokun_tours`);
  }
}
