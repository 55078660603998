import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../../../components/PageTitle";
import { FadeLoader } from "react-spinners";
import ModalButtonsReset from "../../../../components/modals/modalButtons/ModalButtonsReset";
import WebsiteGalleryForm from "../../../../components/WebsiteGallery/WebsiteGalleryForm";
import { toast } from "react-toastify";
import {
  getWebApartmentRoomGallery,
  updateWebApartmentRoomGallery,
} from "../../../../store/actions/websiteApartments";

function ApartmentRoomGallery() {
  const { id, roomId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState();
  const rowStatus = useSelector((store) => store?.websiteApartments?.status);

  useEffect(() => {
    handleGetGallery(roomId);
  }, []);
  const handleGetGallery = (roomId) => {
    dispatch(
      getWebApartmentRoomGallery(roomId, (err, data) => {
        if (!err && data) {
          setFormData(data?.apartmentRooms);
        }
      })
    );
  };
  const handleEditConfirm = (images) => {
    const allFormImages = { ...images };
    dispatch(
      updateWebApartmentRoomGallery(roomId, allFormImages, (err, data) => {
        if (err) {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
        }
        if (!err && data) {
          toast.success("Successfully updated.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          navigate(`/website-management/website-apartments/rooms/${id}`);
        }
      })
    );
  };

  return (
    <>
      <PageTitle
        parentTitle={"Apartment Rooms Gallery"}
        childTitle={formData?.room_name || ""}
        parentLink={`/website-management/website-apartments/rooms/${id}`}
      />
      {formData?.images && rowStatus === "success" ? (
        <WebsiteGalleryForm
          submit={handleEditConfirm}
          gallery={formData?.images}
          main_image={formData?.main_image}
          footer={
            <ModalButtonsReset
              submit={handleEditConfirm}
              reset={() => {
                handleGetGallery(id);
              }}
            />
          }
        />
      ) : (
        <div className="form-loading">
          <FadeLoader
            color={`var(--primary_main)`}
            loading={rowStatus === "pending"}
            size={10}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </>
  );
}

export default ApartmentRoomGallery;
