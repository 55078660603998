import _, { cloneDeep, isEmpty } from "lodash";
import dayjs from "dayjs";
import moment from "moment/moment";

export function customStyleGray(validated) {
  return {
    control: (styles) => ({
      ...styles,
      borderColor: !!validated ? "" : "#ced4da",
    }),
    option: (styles) => {
      return {
        ...styles,
        ":active": {
          ...styles[":active"],
          backgroundColor: `var(--primary_light)`,
        },
      };
    },
  };
}

export function customRequiredStyle(validated) {
  return {
    control: (styles) => ({
      ...styles,
      borderColor: !!validated ? "#dc3545" : "#ced4da",
    }),
    option: (styles) => {
      return {
        ...styles,
        ":active": {
          ...styles[":active"],
          backgroundColor: `var(--primary_light)`,
        },
      };
    },
  };
}

export function directionCutter(bokun_info) {
  if (bokun_info) {
    const keyValuePairs = bokun_info.split(";");

    const directionPair = _.find(keyValuePairs, (pair) =>
      _.startsWith(pair, "Direction")
    );

    return _.last(directionPair.split("-")).trim();
  } else {
    return "";
  }
}

export function findToursDifference(tourHistory) {
  let result = [];
  const copiedTour = cloneDeep(tourHistory);
  if (!isEmpty(copiedTour)) {
    const findInKeys = [
      "state",
      "tour_name",
      "driver_location_url",
      "service_date",
      "start_time",
      "end_date",
      "end_time",
      "pick_up_time",
      "net_price",
      "contact_info",
      "directions",
      "suppliers",
      "pick_up_name",
      "cancellation_reason",
    ];

    copiedTour.forEach((v, i) => {
      if (v.supplier_user_id) {
        v.suppliers = v.supplierUser?.full_name;
      } else {
        v.suppliers = "N/A";
      }
      if (v.direction_id) {
        v.directions = v.direction?.direction_name;
      } else {
        v.directions = "N/A";
      }
      if (v.pick_up_id && !isEmpty(v.pickUpPoint)) {
        v.pick_up_name = v?.pickUpPoint?.pick_up_name;
      } else {
        v.pick_up_name = "N/A";
      }
    });

    copiedTour.map((tour, index) => {
      let updatedData = tour || {};
      let changes = [];

      if (copiedTour[index] && copiedTour[index + 1]) {
        let serviceDateProcessed = false;
        let endDateProcessed = false;
        const updatedTour = copiedTour[index + 1];
        Object.keys(copiedTour[index]).forEach((key) => {
          let oldValue = updatedData[key];
          let newValue = copiedTour[index + 1][key];
          if (findInKeys.includes(key)) {
            if (key === "service_date") {
              oldValue = dayjs(oldValue).isValid()
                ? dayjs(oldValue).format("MM/DD/YYYY")
                : oldValue;
              newValue = dayjs(newValue).isValid()
                ? dayjs(newValue).format("MM/DD/YYYY")
                : newValue;
              if (serviceDateProcessed) {
                return;
              }
              serviceDateProcessed = true;
            }
            if (key === "end_date") {
              oldValue = dayjs(oldValue).isValid()
                ? dayjs(oldValue).format("MM/DD/YYYY")
                : oldValue;
              newValue = dayjs(newValue).isValid()
                ? dayjs(newValue).format("MM/DD/YYYY")
                : newValue;
              if (endDateProcessed) {
                return;
              }
              endDateProcessed = true;
            }

            if (newValue !== oldValue) {
              let keyLabel =
                key === "state"
                  ? "Status"
                  : key === "net_price"
                  ? "Net Price (€)"
                  : key
                      .replaceAll("_", " ")
                      .replace(/\b\w/g, (char) => char.toUpperCase())
                      .replaceAll(" Id", "");
              changes.push(
                `${keyLabel}: ${oldValue || "N/A"} => ${newValue || "N/A"}`
              );
            }
          }
        });

        if (changes.length > 0) {
          let resultStr = `<b>Modified By: ${
            updatedTour?.updatedBy?.full_name || ""
          }</b>;
               <b>Modified Date: ${moment(tour.createdAt).format(
                 "DD/MM/YYYY HH:mm:ss"
               )}</b>;\n${changes.join(";\n")}`;

          result.push(resultStr);
        }
      }
    });
  }
  return result.reverse();
}

export function findVouchersBokunDifference(voucherHistory) {
  let result = [];
  const copiedVoucher = cloneDeep(voucherHistory);
  if (!isEmpty(copiedVoucher)) {
    const findInKeys = [
      "original_reference_number",
      "reference_number",
      "payment_amount",
      "partner_ref_number",
      "passenger_name",
      "passenger_email",
      "passenger_phone",
      "accommodation",
      "adult_members",
      "child_members",
      "infant_members",
      "booking_direction",
      "service_date",
      "state",
      "status",
      "service_date",
      "nationality",
      "cancellation_reason",
      "sales_agent",
      "tour_name",
      "service_date",
      "direction",
      "show_on_PDF",
    ];
    copiedVoucher.forEach((v, i) => {
      if (v.sales_agent_id) {
        v.sales_agent = v.salesAgent?.full_name;
      } else {
        v.sales_agent = "N/A";
      }
      if (v.tour_id) {
        v.tour_name = v.tour?.tour_name;
        v.service_date = v.tour?.service_date;
        v.direction = v.tour?.direction?.direction_name;
      } else {
        v.tour_name = "N/A";
        v.service_date = "N/A";
        v.direction = "N/A";
      }
      v.show_on_PDF = v.show_reference_number ? "Yes" : "No";
      // if (v.payment_amount && !isEmpty(v.currency)) {
      //   v.payment_amount = `${v.payment_amount}${v.currency?.currency_symbol}`;
      // }
    });

    // copiedVoucher.map((voucher, index) => {
    //     let updatedData = voucher || {};
    //     let resultStr = ''
    //     if (copiedVoucher[index] && copiedVoucher[index + 1]) {
    //         findInKeys.forEach(key => {
    //             if (copiedVoucher[index + 1][key] !== updatedData[key]) {
    //                 resultStr =
    //                     `Updated Date: ${dayjs(voucher?.updated_at).format('YYYY-MM-DD HH:mm:ss')},\n
    //                     ${key === 'state'
    //                         ? 'Status'
    //                         : key.replaceAll('_', ' ').replace(/\b\w/g, char => char.toUpperCase())}: ${updatedData[key]} =>
    //             ${copiedVoucher[index + 1][key]},\n` + resultStr;
    //             }
    //         });
    //         result.push(resultStr)
    //     }
    // })

    copiedVoucher.map((voucher, index) => {
      let updatedData = voucher || {};
      let changes = [];

      if (copiedVoucher[index] && copiedVoucher[index + 1]) {
        let serviceDateProcessed = false;
        const updatedVoucher = copiedVoucher[index + 1];
        Object.keys(copiedVoucher[index]).forEach((key) => {
          let oldValue = updatedData[key];
          let newValue = copiedVoucher[index + 1][key];
          if (findInKeys.includes(key)) {
            if (key === "tour_id") {
              if (copiedVoucher[index][key] !== copiedVoucher[index + 1][key]) {
              }
            }
            if (key === "service_date") {
              oldValue = dayjs(oldValue).isValid()
                ? dayjs(oldValue).format("MM/DD/YYYY")
                : oldValue;
              newValue = dayjs(newValue).isValid()
                ? dayjs(newValue).format("MM/DD/YYYY")
                : newValue;
              if (serviceDateProcessed) {
                return;
              }
              serviceDateProcessed = true;
            }

            if (
              key === "tour_name" &&
              copiedVoucher[index]["tour_id"] !==
                copiedVoucher[index + 1]["tour_id"]
            ) {
              let keyLabel = key
                .replaceAll("_", " ")
                .replace(/\b\w/g, (char) => char.toUpperCase())
                .replaceAll(" Id", "");
              changes.push(
                `${keyLabel}: ${oldValue || "N/A"} => ${newValue || "N/A"}`
              );
              return;
            }

            if (newValue !== oldValue) {
              let keyLabel =
                key === "state"
                  ? "Status"
                  : key === "original_reference_number"
                  ? "Reference Number"
                  : key === "reference_number"
                  ? "Confirmation Code"
                  : key === "payment_amount"
                  ? "Payment Amount (€)"
                  : key
                      .replaceAll("_", " ")
                      .replace(/\b\w/g, (char) => char.toUpperCase())
                      .replaceAll(" Id", "");
              changes.push(
                `${keyLabel}: ${oldValue || "N/A"} => ${newValue || "N/A"}`
              );
            }
          }
        });

        if (changes.length > 0) {
          let resultStr = `<b>Modified By: ${
            updatedVoucher?.updatedBy?.full_name || ""
          }</b>;
               <b>Modified Date: ${moment(voucher.createdAt).format(
                 "DD/MM/YYYY HH:mm:ss"
               )}</b>;\n${changes.join(";\n")}`;

          result.push(resultStr);
        }
      }
    });
  }
  return result.reverse();
}

export function findTransferVouchersDifference(history) {
  let result = [];
  const copiedVoucher = cloneDeep(history);

  const findInKeys = [
    "ag_ref_number",
    "reference_number",
    "payment_amount",
    "partner_ref_number",
    "passenger_name",
    "reservation_date",
    "passenger_passport_details",
    "passenger_email",
    "phone",
    "pick_up_address",
    "pick_up_time",
    "flight_time",
    "flight_number",
    "original_point_of_rental",
    "adult_members",
    "child_members",
    "infant_members",
    "booking_direction",
    "service_date",
    "state",
    "status",
    "direction_name",
    "service_date",
    "nationality",
    "cancellation_reason",
    "sales_agent",
    "pre_paid_package",
    "transfer_name",
    "comment",
    "show_on_PDF",
    "pre_paid_package",
  ];

  copiedVoucher.forEach((v) => {
    if (v.sales_agent_id) {
      v.sales_agent = v.salesAgent?.full_name;
    } else {
      v.sales_agent = "N/A";
    }
    if (v.transfer_id) {
      v.transfer_name = v.transfer?.transfer_name;
    } else {
      v.transfer_name = "N/A";
    }
    v.comment = v.hidden_note;
    v.show_on_PDF = v.show_reference_number ? "Yes" : "No";
    v.pre_paid_package = v.pre_paid_package ? "Yes" : "No";
  });

  copiedVoucher.map((voucher, index) => {
    let updatedData = voucher || {};
    let changes = [];

    if (copiedVoucher[index] && copiedVoucher[index + 1]) {
      let serviceDateProcessed = false;
      const updatedVoucher = copiedVoucher[index + 1];
      Object.keys(copiedVoucher[index]).forEach((key) => {
        let oldValue = updatedData[key];
        let newValue = copiedVoucher[index + 1][key];
        if (findInKeys.includes(key)) {
          if (key === "service_date" || key === "reservation_date") {
            oldValue = dayjs(oldValue).isValid()
              ? dayjs(oldValue).format("MM/DD/YYYY")
              : oldValue;
            newValue = dayjs(newValue).isValid()
              ? dayjs(newValue).format("MM/DD/YYYY")
              : newValue;
            if (serviceDateProcessed) {
              return;
            }
            serviceDateProcessed = true;
          }

          if (newValue !== oldValue) {
            let keyLabel =
              key === "state"
                ? "Status"
                : key === "payment_amount"
                ? "Payment Amount (€)"
                : key
                    .replaceAll("_", " ")
                    .replace(/\b\w/g, (char) => char.toUpperCase())
                    .replaceAll(" Id", "");
            changes.push(
              `${keyLabel}: ${oldValue || "N/A"} => ${newValue || "N/A"}`
            );
          }
        }
      });

      if (changes.length > 0) {
        let resultStr = `<b>Modified By: ${
          updatedVoucher?.updatedBy?.full_name || ""
        }</b>;
               <b>Modified Date: ${moment(voucher.createdAt).format(
                 "DD/MM/YYYY HH:mm:ss"
               )}</b>;\n${changes.join(";\n")}`;

        result.push(resultStr);
      }
    }
  });
  return result.reverse();
}

export function titleFromEnv(fieldName) {
  return fieldName
    .replace(/_([a-z])/g, (_, letter) => letter.toUpperCase())
    .replace(/^./, (str) => str.toUpperCase());
}

export function titleFromEnvSeparated(fieldName) {
  return fieldName
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

const keyMapping = {
  villa_name: "name",
  hotel_name: "name",
  suite_name: "name",
  apartment_name: "name",
};

export const normalizeAccommodations = (data) => {
  return data?.map((item) => {
    const keyToRename = Object.keys(keyMapping).find((key) => key in item);

    if (keyToRename) {
      const renamedKey = keyMapping[keyToRename];

      return { ...item, [renamedKey]: item[keyToRename] };
    }
    return item;
  });
};

const keyAccommodationMapping = ["villa", "hotel", "suite", "apartment"];

export const getAccommodationName = (row, key) => {
  const findKey = keyAccommodationMapping?.find((i) => i == row?.property);

  if (key === "accommodation") {
    if (
      row[`${findKey}Rooms`]?.[`parent_${findKey}_id`] === row?.accommodation_id
    ) {
      return row[`${findKey}Rooms`]?.[`parent_${findKey}`]?.[`${findKey}_name`];
    }
  } else if (key === "room") {
    if (
      row[`${findKey}Rooms`]?.[`parent_${findKey}_id`] === row?.accommodation_id
    ) {
      return row[`${findKey}Rooms`]?.room_name;
    }
  }
};
