import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { blockPageAction } from "../../../../store/actions/users";
import { Controller, useForm } from "react-hook-form";
import { getAllRoomFacilities } from "../../../../store/actions/facilities";
import { getAllCurrency } from "../../../../store/actions/currency";
import { isEmpty } from "lodash";

function ApartmentRoomsForm({ submit, footer, formData }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const [facilitiesOptions, setFacilitiesOptions] = useState([]);
  const [selectedFacilities, setSelectedFacilities] = useState([]);
  const allCurrency = useSelector((store) => store.currency.allCurrency);
  const allRoomFacilities = useSelector(
    (store) => store.facilities.allRoomFacilities
  );
  const {
    reset,
    watch,
    trigger,
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isDirty, isSubmitted },
  } = useForm({
    defaultValues: id && !isEmpty(formData) ? { ...formData } : {},
  });

  useEffect(() => {
    dispatch(getAllCurrency());
    dispatch(getAllRoomFacilities());
  }, []);

  useEffect(() => {
    if (id && formData) {
      reset(formData);
    }
  }, [formData]);

  useEffect(() => {
    if (allRoomFacilities) {
      const updatedOptions = allRoomFacilities?.map((facility) => {
        return {
          ...facility,
        };
      });
      setFacilitiesOptions(updatedOptions);
    }
  }, [allRoomFacilities]);

  useEffect(() => {
    if (formData?.facilities && formData?.facilities.length > 0) {
      const initialSelectedFacilities = formData?.facilities.map((i) => {
        const facility = i.facilityId || i;
        return {
          ...facility,
        };
      });
      setSelectedFacilities(initialSelectedFacilities);
    }
  }, [formData?.facilities]);

  useEffect(() => {
    if (id && formData) {
      if (isDirty) {
        setIsFormDisabled(false);
        dispatch(blockPageAction(`/website-management/hotels/rooms/${id}`));
      } else {
        setIsFormDisabled(true);
        dispatch(blockPageAction(""));
      }
    }
  }, [isDirty]);

  const onSubmit = (data) => {
    submit(data);
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="form-block">
          <Modal.Body>
            <Row>
              <Col sm={12} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Room Name
                    <span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Form.Control
                    id="room_name"
                    name="room_name"
                    isValid={isSubmitted && !errors.room_name}
                    isInvalid={isSubmitted && !!errors.room_name}
                    {...register("room_name", {
                      required: "Required Field",
                      pattern: {
                        value: /^(?!\s*$).+/,
                        message: "Only spaces are not allowed",
                      },
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.room_name && errors.room_name.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={12} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Price (€)
                    <span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    maxLength={50}
                    id="price"
                    name="price"
                    {...register("price", { required: "Required Filed" })}
                    isInvalid={!!errors.price}
                    isValid={isSubmitted && !errors.price}
                    onInput={(e) => {
                      let inputValue = e.target.value;
                      inputValue = inputValue.replace(/[^0-9.]/g, "");

                      const dotCount = (inputValue.match(/\./g) || []).length;
                      if (dotCount > 1) {
                        inputValue = inputValue.substring(
                          0,
                          inputValue.lastIndexOf(".") + 1
                        );
                      }
                      if (inputValue.startsWith(".")) {
                        inputValue = "0" + inputValue;
                      }
                      if (inputValue.includes(".")) {
                        let [integerPart, decimalPart] = inputValue.split(".");
                        decimalPart = decimalPart.substring(0, 2);
                        if (integerPart && integerPart.length > 8) {
                          integerPart = integerPart.substring(0, 8);
                        }
                        inputValue = `${integerPart}.${decimalPart}`;
                      }
                      if (
                        inputValue.startsWith("0") &&
                        inputValue.length > 1 &&
                        inputValue[1] !== "."
                      ) {
                        inputValue = inputValue.substring(1);
                      }
                      if (inputValue.length > 8) {
                        if (!inputValue.includes(".")) {
                          inputValue = inputValue.substring(0, 8) + ".00";
                        } else {
                          let [integerPart, decimalPart] =
                            inputValue.split(".");
                          decimalPart = decimalPart.substring(0, 2);
                          inputValue = `${integerPart}.${decimalPart}`;
                        }
                      }
                      setValue("price", inputValue, {
                        shouldDirty: true,
                      });
                    }}
                  />
                  {errors?.price?.message ? (
                    <span className="required-message">
                      {errors.price.message}
                    </span>
                  ) : null}
                </Form.Group>
              </Col>
              <Col sm={12} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Room Size
                    <span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Form.Control
                    id="room_size"
                    name="room_size"
                    isValid={isSubmitted && watch("room_size")}
                    isInvalid={isSubmitted && !watch("room_size")}
                    {...register("room_size", { required: true })}
                  />
                  <Form.Control.Feedback type="invalid">
                    Required Field
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={12} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Room Capacity
                    <span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Form.Control
                    id="room_capacity"
                    name="room_capacity"
                    type="number"
                    isValid={isSubmitted && watch("room_capacity")}
                    isInvalid={isSubmitted && !watch("room_capacity")}
                    {...register("room_capacity", { required: true })}
                  />
                  <Form.Control.Feedback type="invalid">
                    Required Field
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    rows={5}
                    as="textarea"
                    id="room_description"
                    name="room_description"
                    // isInvalid={isSubmitted && !watch("room_description")}
                    isValid={isSubmitted && !watch("room_description")}
                    {...register("room_description")}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Facilities
                    <span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Controller
                    control={control}
                    name="facilities"
                    rules={{
                      required: "At least one facility must be selected",
                      validate: (value) =>
                        value.length > 0 ||
                        "At least one facility must be selected",
                    }}
                    render={({ field, fieldState }) => (
                      <>
                        <div
                          id="hotel_facilities"
                          className={"gridContainerStyle"}
                        >
                          {facilitiesOptions?.map((facility) => (
                            <Form.Check.Label
                              htmlFor={`facility-${facility.id}`}
                              className={"facilityCardStyle"}
                            >
                              <Form.Check
                                key={facility.id}
                                type="checkbox"
                                id={`facility-${facility.id}`}
                                checked={selectedFacilities.some(
                                  (sf) => sf.id === facility.id
                                )}
                                onChange={() => {
                                  let updatedSelectedFacilities;
                                  if (
                                    selectedFacilities.some(
                                      (sf) => sf.id === facility.id
                                    )
                                  ) {
                                    updatedSelectedFacilities =
                                      selectedFacilities.filter(
                                        (sf) => sf.id !== facility.id
                                      );
                                  } else {
                                    updatedSelectedFacilities = [
                                      ...selectedFacilities,
                                      {
                                        id: facility.id,
                                        facility_name: facility.facility_name,
                                        facilityId: {
                                          facility_name: facility.facility_name,
                                        },
                                      },
                                    ];
                                  }
                                  setSelectedFacilities(
                                    updatedSelectedFacilities
                                  );
                                  field.onChange(updatedSelectedFacilities);
                                  trigger("facilities");
                                }}
                              />

                              {facility.facility_name}
                            </Form.Check.Label>
                          ))}
                        </div>
                        {fieldState?.error && (
                          <div className="required_message">
                            {fieldState?.error?.message}
                          </div>
                        )}
                      </>
                    )}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
        </div>
        <Modal.Footer>
          {React.cloneElement(footer, { disabled: isFormDisabled })}
        </Modal.Footer>
      </Form>
    </>
  );
}

export default ApartmentRoomsForm;
