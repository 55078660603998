import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Table from "../../../components/table";
import Pagination from "../../../components/pagination/Pagination";
import SearchFilterBar from "../../../components/search_filter_bar/SearchFilterBar";
import TableTitle from "../../../components/table_title/TableTitle";
import { setPage, sortAction } from "../../../store/actions/search";
import DeleteModal from "../../../components/modals/DeleteModal2";
import { getColumns } from "./column";
import {
  addTourAction,
  deleteTour,
  changeTourStatusAction,
  getToursAction,
  updateTourPickUpId,
  getSingleTour,
  updateSingleTourType,
  fetchWebTours,
} from "../../../store/actions/websiteTours";
import WebToursForm from "./WebToursForm";
import { isEmpty } from "lodash";
import UpdateStatusModal from "../../../components/modals/UpdateStatusModal";
import EditPickUpPointModal from "../../../components/modals/EditPickUpPointModal";
import EditDriverLocationModal from "../../../components/modals/EditDriverLocationModal";
import MobileLayout from "../../../components/mobileLayout/MobileLayout";
import { website_tours_mobile_column } from "../../../staticVariables/mobileColumns";
import DynamicModal from "../../../components/modals/DynamicModal";
import ModalButtons from "../../../components/modals/modalButtons/ModalButtons";
import UpdateFeaturedTypeModal from "../../../components/modals/UpdateFeaturedTypeModal";

export const tourStatus = [
  {
    id: "Active",
    color: "green",
  },
  {
    id: "Inactive",
    color: "#dc3545",
  },
];

function WebTours() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [rowId, setRowId] = useState("");
  const [show, setShow] = useState(false);
  const [tourId, setTourId] = useState("");
  const [pickUpId, setPickUpId] = useState("");
  const [formData, setFormData] = useState({});
  const [showDelete, setShowDelete] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [noteValidate, setNoteValidate] = useState(true);
  const [singleTour, setSingleTour] = useState({});
  const [showFeaturedModal, setShowFeaturedModal] = useState(false);
  let searchConfig = useSelector((store) => store?.search);
  const account = useSelector((store) => store.users.account);
  let rows = useSelector((store) => store?.websiteTours?.tours);
  const count = useSelector((store) => store.websiteTours.count);
  let rowStatus = useSelector((store) => store?.websiteTours?.status);
  const [showEditPickUpPoint, setShowEditPickUpPoint] = useState(false);
  let fetchStatus = useSelector((store) => store?.websiteTours?.fetchStatus);
  let actionStatus = useSelector((store) => store?.websiteTours?.actionStatus);
  const [showDriverLocationModal, setShowDriverLocationModal] = useState(false);

  useEffect(() => {
    getToursList(searchConfig);
  }, []);

  const redirectToPreview = useCallback((params) => {
    navigate(`/website-management/website-tours/${params}`);
  }, []);

  const handleClose = () => {
    setShowDelete(false);
    setShow(false);
    setFormData({});
    setShowStatus(false);
    setNoteValidate(true);
    setShowFeaturedModal(false);
  };
  const handleCloseEditPickup = () => {
    setTourId("");
    setPickUpId("");
    setFormData({});
    setShowEditPickUpPoint(false);
  };

  const handleDelete = (ev, params) => {
    ev.stopPropagation();
    setRowId(params);
    setShowDelete(true);
  };

  const handleEditPickUpPoint = (pickUp) => {
    setShowEditPickUpPoint(true);
    setPickUpId(pickUp.pick_up_id);
    setFormData({ ...pickUp });
    setTourId(pickUp.id);
  };

  const handleConfirmDelete = (e, id) => {
    dispatch(
      deleteTour(rowId?.id, (err, data) => {
        if (data) {
          toast.success("Successfully deleted.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          if (rows && rows.length === 1 && searchConfig.page !== 1) {
            searchConfig.page = searchConfig.page - 1;
            dispatch(setPage(searchConfig.page));
          }
          setRowId(null);
          setShowDelete(false);
          getToursList(searchConfig);
        }
        if (err) {
          toast.error(err.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          handleClose();
        }
      })
    );
  };

  const handleShow = () => {
    setShow(true);
  };

  const handleSort = (model) => {
    if (model.length) {
      searchConfig.sort = model;
      dispatch(sortAction(model));
    } else {
      model = searchConfig.sort;
    }
    getToursList(searchConfig);
  };

  const getToursList = (searchConfig) => {
    dispatch(getToursAction(searchConfig));
  };

  // const exportExcel = () => {
  //   dispatch(exportExcelToursAction(searchConfig));
  // };

  const handleUpdateStatus = (params) => {
    setRowId(params);
    setShowStatus(true);
  };

  const handleConfirmUpdateStatus = () => {
    dispatch(
      changeTourStatusAction(rowId?.id, (err, data) => {
        if (data) {
          toast.success("Successfully updated.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          getToursList(searchConfig);
        } else if (err) {
          toast.error(err.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
        }
        setShowStatus(false);
      })
    );
  };

  const handleOpenDriverModal = (tour) => {
    setFormData({ ...tour });
    setShowDriverLocationModal(true);
  };

  const handleCloseDriverModal = () => {
    setFormData({});
    setShowDriverLocationModal(false);
  };
  const handleNavigateGallery = (id) => {
    navigate(`/website-management/website-tours/gallery/${id}`);
  };

  const handleShowFeaturedStatusModal = async (ev, id) => {
    ev.stopPropagation();
    setShowFeaturedModal(true);
    dispatch(
      getSingleTour(id, (err, data) => {
        if (data && data?.tour) {
          setSingleTour(data?.tour);
        }
      })
    );
  };

  const handleChangeFeaturedType = () => {
    let type;
    if (singleTour?.type === "Featured") {
      type = null;
    } else {
      type = "Featured";
    }
    dispatch(
      updateSingleTourType(singleTour?.id, type, (err, data) => {
        if (!err) {
          setSingleTour({});
          setShowFeaturedModal(false);
          toast.success("Successfully Updated", {
            autoClose: 3000,
          });
          getToursList(searchConfig);
        }
      })
    );
  };

  const columns = getColumns({
    rows,
    navigate,
    handleDelete,
    redirectToPreview,
    handleUpdateStatus,
    handleEditPickUpPoint,
    handleOpenDriverModal,
    handleNavigateGallery,
    handleShowFeaturedStatusModal,
    role: !isEmpty(account) ? account.role : "",
  });

  const handleAddConfirm = async (data) => {
    dispatch(
      addTourAction(data, (err, data) => {
        if (data) {
          toast.success("Successfully created.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          dispatch(getToursAction(searchConfig));
          handleClose();
        } else if (err) {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
        }
      })
    );
  };
  const handleEditPickUpPoints = (pick_up_id, pick_up_time, id) => {
    dispatch(
      updateTourPickUpId({ pick_up_id, id, pick_up_time }, (err, data) => {
        if (err) {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
        } else {
          if (data) {
            toast.success("Successfully updated.", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });

            handleCloseEditPickup();
            getToursList(searchConfig);
          }
        }
      })
    );
  };

  const handleEditDriverLocation = (data) => {
    dispatch(
      updateTourPickUpId({ ...data }, (err, data) => {
        if (err) {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
        } else {
          if (data) {
            toast.success("Successfully updated.", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });

            handleCloseEditPickup();
            getToursList(searchConfig);
          }
        }
      })
    );
  };

  const handleFetch = () => {
    dispatch(
      fetchWebTours((err, data) => {
        if (data && data?.status === 200) {
          toast.success(
            `Successfully Fetched ${
              data?.result.createdTours + data?.result.updatedTours
            } Records. (Created: ${data?.result.createdTours}, Updated: ${
              data?.result.updatedTours
            }  )`,
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 10000,
            }
          );
          getToursList(searchConfig);
        }
        if (err) {
          toast.error(err || "error", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
        }
      })
    );
  };

  return (
    <div className="admin_tables_block">
      <TableTitle
        title={"Website Tours"}
        handleShow={handleShow}
        addNew={
          (account && account.role === "Admin") ||
          account.role === "Tour Manager"
        }
        // fetch={
        //   !isEmpty(account) && ["Admin", "Tour Manager"].includes(account.role)
        //     ? handleFetch
        //     : false
        // }
        // fetchStatus={fetchStatus}
      />
      <SearchFilterBar
        getList={getToursList}
        columns={columns}
        setShowFilter={setShowFilter}
        showFilter={showFilter}
      />
      <Table
        status={rowStatus}
        rows={rows || []}
        columns={columns}
        setShow={setShow}
        setSort={handleSort}
        checkboxSelection={false}
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
      />
      <MobileLayout
        rows={rows || []}
        rowStatus={rowStatus}
        columns={columns}
        setShow={setShow}
        changeStatusHandler={handleConfirmUpdateStatus}
        parentTitle={"Website Tours"}
        link="/website-management/website-tours"
        mobileCol={website_tours_mobile_column}
        status={tourStatus}
        search={getToursList}
      />
      <DynamicModal
        show={show}
        hide={() => setShow(false)}
        title={"Add New Tour"}
        body={
          <WebToursForm
            submit={handleAddConfirm}
            footer={
              <ModalButtons
                submit={handleAddConfirm}
                cancel={() => setShow(false)}
                isLoading={actionStatus === "pending"}
              />
            }
          />
        }
      />
      {!isEmpty(formData) && (
        <EditPickUpPointModal
          className="react-select"
          classNamePrefix="react-select"
          Modal
          title={"Change Pick-Up Point"}
          tourId={tourId}
          value={pickUpId}
          setValue={setPickUpId}
          confirm={handleEditPickUpPoints}
          show={showEditPickUpPoint}
          handleClose={handleCloseEditPickup}
          setValidate={setNoteValidate}
          validate={noteValidate}
          formData={formData}
          setFormData={setFormData}
        />
      )}
      {!isEmpty(formData) && (
        <EditDriverLocationModal
          className="react-select"
          classNamePrefix="react-select"
          Modal
          title={"Change Driver Location Url"}
          tourId={tourId}
          value={pickUpId}
          setValue={setPickUpId}
          confirm={handleEditDriverLocation}
          show={showDriverLocationModal}
          handleClose={handleCloseDriverModal}
          setValidate={setNoteValidate}
          validate={noteValidate}
          formData={formData}
          setFormData={setFormData}
        />
      )}
      <DeleteModal
        show={showDelete}
        handleClose={() => handleClose()}
        confirm={(e) => handleConfirmDelete(e, rowId)}
        content={`You are about to delete the #${rowId?.id} tour. Are you sure?`}
        title={"Delete Website Tour"}
        actionStatus={actionStatus}
      />
      <UpdateStatusModal
        show={showStatus}
        handleConfirmUpdate={handleConfirmUpdateStatus}
        handleClose={handleClose}
        text={`You're about to ${
          rowId?.status === "Active" ? "de-activate" : "activate"
        }  the ${rowId?.tour_name} tour. Are you sure?`}
        title={"Update Website Tour"}
        actionStatus={actionStatus}
      />
      <UpdateFeaturedTypeModal
        title={"Tour"}
        handleClose={handleClose}
        type={singleTour?.type}
        showUpdate={showFeaturedModal}
        handleConfirmUpdate={handleChangeFeaturedType}
        activeText={`You're about to mark  ${singleTour?.tour_name}'s tour as featured. Are you sure?`}
        deActiveText={`You're about to mark ${singleTour?.tour_name}'s tour as not featured. Are you sure?`}
      />
      <Pagination
        rowStatus={rowStatus}
        count={count}
        limit={10}
        getList={getToursList}
      />
    </div>
  );
}

export default WebTours;
