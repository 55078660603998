import { takeLatest, call, put } from "redux-saga/effects";
import {
  GET_ALL_FACILITIES_REQUEST,
  GET_ALL_FACILITIES_SUCCESS,
  GET_ALL_FACILITIES_FAIL,
  GET_ALL_ROOM_FACILITIES_REQUEST,
  GET_ALL_ROOM_FACILITIES_SUCCESS,
  GET_ALL_ROOM_FACILITIES_FAIL,
} from "../actions/facilities";
import Facility from "../../api/website_management/hotelsFacilities";

export default function* watcher() {
  yield takeLatest(GET_ALL_FACILITIES_REQUEST, handleGetAllFacilities);
  yield takeLatest(GET_ALL_ROOM_FACILITIES_REQUEST, handleGetAllRoomFacilities);
}

function* handleGetAllFacilities() {
  try {
    const { data } = yield call(Facility.getAllFacilities);
    yield put({
      type: GET_ALL_FACILITIES_SUCCESS,
      payload: {
        data: data,
      },
    });
  } catch (e) {
    yield put({
      type: GET_ALL_FACILITIES_FAIL,
      payload: {
        massage: e.massage,
      },
    });
  }
}
function* handleGetAllRoomFacilities() {
  try {
    const { data } = yield call(Facility.getAllRoomFacilities);
    yield put({
      type: GET_ALL_ROOM_FACILITIES_SUCCESS,
      payload: {
        data: data,
      },
    });
  } catch (e) {
    yield put({
      type: GET_ALL_ROOM_FACILITIES_FAIL,
      payload: {
        massage: e.massage,
      },
    });
  }
}
