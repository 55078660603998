import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getHashedIdAction, sortAction } from "../../store/actions/search";
import { getColumns } from "./column";
import TableTitle from "../../components/table_title/TableTitle";
import SearchFilterBar from "../../components/search_filter_bar/SearchFilterBar";
import Table from "../../components/table";
import UpdateStatusModal from "../../components/modals/UpdateStatusModal";
import DeleteModal2 from "../../components/modals/DeleteModal2";
import Pagination from "../../components/pagination/Pagination";
import {
  deleteUser,
  exportExcelUserAction,
  getUsersListAction,
  resendEmailToUser,
  updateUserStatus,
} from "../../store/actions/users";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import TourManagersForm from "./userForms/TourManagersForm";
import SuppliersForm from "./userForms/SuppliersForm";
import DriversForm from "./userForms/DriversForm";
import MobileLayout from "../../components/mobileLayout/MobileLayout";
import { user_mobile_column } from "../../staticVariables/mobileColumns";
import SalesAgentForm from "./userForms/SalesAgentForm";
import Account from "../../services/Account";
import { globalUsersColumns } from "./globalUsersolumn";
import DynamicModal from "../../components/modals/DynamicModal";
import SendEmailToUserModal from "../../components/modals/SendEmailToUserModal";

export const userStatus = [
  {
    id: "Active",
    color: "green",
  },
  {
    id: "Inactive",
    color: "#dc3545",
  },
];

function Users(props) {
  let rows = useSelector((store) => store.users.usersList);
  let rowStatus = useSelector((store) => store.users.usersListRequestStatus);
  let searchConfig = useSelector((store) => store.search);
  let actionStatus = useSelector((store) => store?.users?.actionStatus);
  let exportExcelStatus = useSelector((store) => store.users.exportExcelStatus);
  const count = useSelector((store) => store?.users?.pageCount);
  const [showDelete, setShowDelete] = useState(false);
  const [showTourManager, setShowTourManager] = useState(false);
  const [showSupplier, setShowSupplier] = useState(false);
  const [showDrivers, setShowDrivers] = useState(false);
  const [showSaleAgent, setShowSalesAgent] = useState(false);
  const [formData, setFormData] = useState({});
  const [showStatus, setShowStatus] = useState(false);
  const [showSelectedDelete, setShowSelectedDelete] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [popupTitle, setPopupTitle] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [rowId, setRowId] = useState("");
  const dispatch = useDispatch();
  const [showFilter, setShowFilter] = useState(false);
  const [noteValidate, setNoteValidate] = useState(true);
  const [showResendEmailModal, setShowResendEmailModal] = useState(false);
  const [row, setRow] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    getUsersList(searchConfig);
  }, []);

  const getUsersList = (searchConfig) => {
    dispatch(getUsersListAction(searchConfig));
  };

  // Redirect to preview page on click the data of the table
  const redirectToPages = (event) => {
    if (event.field == "recovery_email" && event.row.recovery_email != null) {
      for (var value of rows) {
        if (value.email == event.formattedValue) {
          navigate(`/users/${value.id}`);
        }
      }
    }
  };

  const redirectToPreview = useCallback((id) => {
    dispatch(
      getHashedIdAction(id, (err, data) => {
        if (data?.hash) {
          navigate(`/users/${data?.hash}`);
        }
      })
    );
  }, []);

  const handleClose = () => {
    setShowDelete(false);
    setShowSupplier(false);
    setShowDrivers(false);
    setShowSalesAgent(false);
    setShowTourManager(false);
    setPopupTitle("");
    setFormData({});
    setShowStatus(false);
    setNoteValidate(true);
    setShowResendEmailModal(false);
    setRow(null);
    setTimeout(() => {
      setDisabled(false);
    }, 400);
  };

  const handleDelete = (rowId) => {
    setShowDelete(true);
    setRowId(rowId);
  };

  const handleConfirmDelete = (e, id) => {
    setDisabled(true);
    dispatch(
      deleteUser(rowId.id, (err, data) => {
        if (data) {
          dispatch(getUsersListAction(searchConfig));
          toast.success("Successfully deleted.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          handleClose();
        } else if (err) {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          handleClose();
        }
      })
    );
  };

  const handleShow = (modal) => {
    switch (modal) {
      case "tour_manager": {
        setShowTourManager(true);
        break;
      }
      case "driver": {
        setShowDrivers(true);
        break;
      }
      case "supplier": {
        setShowSupplier(true);
        break;
      }
      case "sales agent": {
        setShowSalesAgent(true);
        break;
      }
      default: {
        setShowSupplier(true);
        break;
      }
    }
  };

  const handleSort = (model, page) => {
    if (model.length) {
      searchConfig.sort = model;
      dispatch(sortAction(model));
    } else {
      model = searchConfig.sort;
    }
    getUsersList(searchConfig);
  };

  const exportExcel = (params) => {
    dispatch(exportExcelUserAction(searchConfig));
  };

  const deleteSelectedRows = () => {
    setShowSelectedDelete(true);
  };
  const deleteSelectedRowsConfirm = (reason) => {};

  const handleUpdateStatus = (params) => {
    setRowId(params);
    setShowStatus(true);
  };

  const handleConfirmUpdateStatus = () => {
    if (!isEmpty(rowId)) {
      const status = rowId?.status === "Active" ? "Inactive" : "Active";
      dispatch(
        updateUserStatus(status, rowId.id, (err, data) => {
          if (data) {
            toast.success(
              data?.updatedUser?.status === "Inactive"
                ? "Access to the system is disabled for the selected user."
                : "Access to the system is enabled for the selected user.",
              {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
              }
            );
            setShowStatus(false);
            setRowId("");
            getUsersList(searchConfig);
          } else if (err) {
            toast.error("Something went wrong!", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
          }
        })
      );
    }
  };

  const handleOpenSendEmailToUser = (row) => {
    setShowResendEmailModal(true);
    setRow(row);
  };

  const handleResendEmail = (id) => {
    setDisabled(true);
    dispatch(
      resendEmailToUser(id, (err, data) => {
        if (data && data?.status === 200) {
          toast.success("Successfully Sent", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          handleClose();
        }
        if (err) {
          toast.error(err.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          setDisabled(false);
        }
      })
    );
  };

  const columns =
    Account.get().role === "Super Admin"
      ? globalUsersColumns(rows)
      : getColumns({
          handleOpenSendEmailToUser,
          handleUpdateStatus,
          rows,
          redirectToPreview,
          handleDelete,
          navigate,
        });

  return (
    <div className="admin_tables_block">
      <TableTitle
        dropDown={!Account.isSuperAdmin()}
        handleShow={!Account.isSuperAdmin() ? handleShow : null}
        title={"Users"}
        addNew={!Account.isSuperAdmin()}
        exportExcel={exportExcel}
        selectedRows={selectedRows}
        deleteSelectedRows={deleteSelectedRows}
        exportExcelStatus={exportExcelStatus}
      />
      <SearchFilterBar
        getList={getUsersList}
        columns={columns}
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        usersPage={true}
      />
      <Table
        status={rowStatus}
        rows={rows}
        columns={columns}
        setPopupTitle={setPopupTitle}
        redirectToPages={redirectToPages}
        parentTitle={"Users"}
        link="/users"
        fetch={true}
        setSort={handleSort}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />
      <MobileLayout
        rows={rows || []}
        rowStatus={rowStatus}
        columns={columns}
        setShow={setShowDrivers}
        setPopupTitle={setPopupTitle}
        redirectToPages={redirectToPages}
        changeStatusHandler={handleConfirmUpdateStatus}
        parentTitle={"Users"}
        link="/users"
        mobileCol={user_mobile_column}
        status={userStatus}
        search={getUsersList}
      />

      <DynamicModal
        show={showTourManager}
        hide={() => setShowTourManager(false)}
        title={"Add New Tour Manager"}
        body={
          <TourManagersForm
            setFormData={setFormData}
            handleClose={handleClose}
            getUsersList={getUsersList}
            add_new_modal={true}
            actionStatus={actionStatus}
          />
        }
      />
      <DynamicModal
        show={showSupplier}
        hide={() => setShowSupplier(false)}
        title={"Add New Supplier"}
        body={
          <SuppliersForm
            setFormData={setFormData}
            handleClose={handleClose}
            getUsersList={getUsersList}
            add_new_modal={true}
            actionStatus={actionStatus}
          />
        }
      />
      <DynamicModal
        show={showDrivers}
        hide={() => setShowDrivers(false)}
        title={"Add New Driver"}
        body={
          <DriversForm
            setFormData={setFormData}
            handleClose={handleClose}
            getUsersList={getUsersList}
            add_new_modal={true}
            actionStatus={actionStatus}
          />
        }
      />
      <DynamicModal
        show={showSaleAgent}
        hide={() => setShowSalesAgent(false)}
        title={"Add New Sales Agent"}
        body={
          <SalesAgentForm
            setFormData={setFormData}
            handleClose={handleClose}
            getUsersList={getUsersList}
            add_new_modal={true}
            actionStatus={actionStatus}
          />
        }
      />

      <UpdateStatusModal
        show={showStatus}
        handleConfirmUpdate={handleConfirmUpdateStatus}
        handleClose={handleClose}
        text={`You're about to ${
          rowId?.status === "Active" ? "disable" : "enable"
        }  login for the ${
          rowId?.user_name || rowId.full_name
        } user to the System. Are you sure?`}
        title={rowId?.status === "Active" ? "Disable Login" : "Enable Login"}
        actionStatus={actionStatus}
      />
      <DeleteModal2
        show={showDelete}
        handleClose={() => handleClose()}
        confirm={(e) => handleConfirmDelete(e, rowId?.id)}
        content={`You are about to delete the #${rowId.id} user. Are you sure?`}
        title={"Delete"}
        actionStatus={actionStatus}
      />
      <DeleteModal2
        show={showSelectedDelete}
        handleClose={() => setShowSelectedDelete(false)}
        confirm={() => deleteSelectedRowsConfirm()}
        content={"You are about to delete selected items. Are you sure?"}
        title={"Delete"}
        actionStatus={actionStatus}
      />
      <SendEmailToUserModal
        show={showResendEmailModal}
        title={"Resend Activation Email to User"}
        confirm={() => handleResendEmail(row?.id)}
        content={`You are about to resend the activation email to the ${
          row?.full_name || ""
        } user. Are you sure?`}
        disabled={disabled}
        handleClose={() => handleClose()}
        actionStatus={actionStatus}
      />
      <Pagination
        rowStatus={rowStatus}
        count={count}
        limit={10}
        getList={getUsersList}
      />
    </div>
  );
}

export default Users;
