import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { createTheme, TextField, ThemeProvider } from "@mui/material";
import { getAllPickUpPointsAction } from "../../../store/actions/pickUpPoints";
import { blockPageAction, getAllSuppliers } from "../../../store/actions/users";
import { getAllDirectionsAction } from "../../../store/actions/directions";
import { Controller, useForm } from "react-hook-form";
import { getAllCurrency } from "../../../store/actions/currency";
import { getAllCategories } from "../../../store/actions/categories";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Stack from "@mui/material/Stack";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";

function WebToursForm({ submit, footer, formData }) {
  const allCategories = useSelector((store) => store.categories.allCategories);
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const {
    reset,
    watch,
    control,
    trigger,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isDirty, isSubmitted },
  } = useForm();

  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllCurrency());
    dispatch(getAllCategories());
    dispatch(getAllPickUpPointsAction());
    dispatch(getAllSuppliers());
    dispatch(getAllDirectionsAction());
  }, []);

  useEffect(() => {
    if (id && formData) {
      reset(formData);
    }
  }, [formData]);

  useEffect(() => {
    if (id && formData) {
      if (isDirty) {
        setIsFormDisabled(false);
        dispatch(blockPageAction(`/website-management/tours/${id}`));
      } else {
        setIsFormDisabled(true);
        dispatch(blockPageAction(""));
      }
    }
  }, [isDirty]);

  useEffect(() => {
    if (allCategories) {
      const updatedOptions = allCategories?.map((category) => {
        return {
          ...category,
        };
      });
      setCategoriesOptions(updatedOptions);
    }
  }, [allCategories]);

  useEffect(() => {
    if (formData?.categories && formData?.categories.length > 0) {
      const initialSelectedCategories = formData?.categories.map((i) => {
        const category = i.categoryId || i;
        return {
          ...category,
        };
      });
      setSelectedCategories(initialSelectedCategories);
    }
  }, [formData?.categories]);

  const onSubmit = (data) => {
    submit(data);
  };

  const getCustomStylesForInput = (field) => {
    const isInvalid = errors[field];
    return {
      borderColor: isSubmitted
        ? isInvalid
          ? "#dc3545"
          : "#198754"
        : "#ced4da",
    };
  };
  const getCustomStylesForDate = (field) => {
    const isInvalid = errors[field];
    const isValid = isSubmitted && !isInvalid;

    return createTheme({
      components: {
        MuiOutlinedInput: {
          styleOverrides: {
            notchedOutline: {
              borderColor: isValid ? "#198754" : "#ced4da",
            },
          },
        },
      },
    });
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="form-block">
          <Modal.Body>
            <Row>
              <Col sm={6} lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Tour Name
                    <span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Form.Control
                    id="tour_name"
                    name="tour_name"
                    maxLength={254}
                    isInvalid={isSubmitted && !!errors.tour_name}
                    isValid={isSubmitted && !errors.tour_name}
                    {...register("tour_name", {
                      required: "Required field",
                      pattern: {
                        value: /^(?!\s*$).+/,
                        message: "Only spaces are not allowed",
                      },
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.tour_name
                      ? errors.tour_name.message
                      : "Required Field"}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={3} lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Price (€)</Form.Label>
                  <Form.Control
                    name="price"
                    type={"number"}
                    id="web_tour_price"
                    {...register("price")}
                    style={getCustomStylesForInput("price")}
                  />
                </Form.Group>
              </Col>
              <Col sm={3} lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label className="label-m-1r">Duration</Form.Label>
                  <Form.Control
                    id="transfer_duration"
                    name="duration"
                    type="text"
                    maxLength={254}
                    value={watch("duration")}
                    isValid={isSubmitted && !errors.duration}
                    isInvalid={isSubmitted && !!errors.duration}
                    {...register("duration", {
                      pattern: {
                        value: /^(?!\s*$).+/,
                        message: "Only spaces are not allowed",
                      },
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.duration && errors.duration.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>From</Form.Label>
                  <Form.Control
                    name="from"
                    id="web_tour_from"
                    maxLength={254}
                    isValid={isSubmitted && !errors.from}
                    isInvalid={isSubmitted && !!errors.from}
                    {...register("from", {
                      pattern: {
                        value: /^(?!\s*$).+/,
                        message: "Only spaces are not allowed",
                      },
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.from && errors.from.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={6} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="label-m-1r">To</Form.Label>
                  <Form.Control
                    name="to"
                    type="text"
                    maxLength={254}
                    id="web_tour_to"
                    isValid={isSubmitted && !errors.to}
                    isInvalid={isSubmitted && !!errors.to}
                    {...register("to", {
                      pattern: {
                        value: /^(?!\s*$).+/,
                        message: "Only spaces are not allowed",
                      },
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.to && errors.to.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={6} lg={3}>
                <Form.Label className="label-m-1r">Start Date </Form.Label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3} id={"web_tour_service_date"}>
                    <ThemeProvider theme={getCustomStylesForDate("start_date")}>
                      <DesktopDatePicker
                        name={"start_date"}
                        className="month_year_picker"
                        views={["year", "month", "day"]}
                        value={
                          watch("start_date") && dayjs(watch("start_date"))
                        }
                        maxDate={watch("end_date") && dayjs(watch("end_date"))}
                        onChange={(newValue) => {
                          if (newValue) {
                            setValue(
                              "start_date",
                              dayjs(newValue).format("YYYY-MM-DD"),
                              {
                                shouldDirty: true,
                              }
                            );
                          } else {
                            setValue("start_date", null, {
                              shouldDirty: true,
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            disabled={watch("send") === "true"}
                          />
                        )}
                      />
                    </ThemeProvider>
                  </Stack>
                </LocalizationProvider>
              </Col>
              <Col sm={6} lg={3}>
                <Form.Label className="label-m-1r"> End Date </Form.Label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3} id={"web_tour_end_date"}>
                    <ThemeProvider theme={getCustomStylesForDate("end_date")}>
                      <DesktopDatePicker
                        name={"end_date"}
                        className="month_year_picker"
                        views={["year", "month", "day"]}
                        minDate={
                          watch("start_date") && dayjs(watch("start_date"))
                        }
                        value={
                          (watch("start_date") &&
                            !watch("end_date") &&
                            dayjs(watch("start_date"))) ||
                          (watch("end_date") && dayjs(watch("end_date")))
                        }
                        onChange={(newValue) => {
                          if (newValue) {
                            setValue(
                              "end_date",
                              dayjs(newValue).format("YYYY-MM-DD"),
                              {
                                shouldDirty: true,
                              }
                            );
                          } else {
                            setValue("end_date", null, {
                              shouldDirty: true,
                            });
                          }
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </ThemeProvider>
                  </Stack>
                  {errors?.end_date && watch("end_date") && (
                    <span className="custom-invalid-feedback">
                      Invalid Date
                    </span>
                  )}
                </LocalizationProvider>
              </Col>
            </Row>
            <Row>
              <Col sm={6} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Scale</Form.Label>
                  <Form.Control
                    type="text"
                    name="scale"
                    maxLength={254}
                    id="web_tour_scale"
                    value={watch("scale")}
                    isValid={isSubmitted && !errors.scale}
                    isInvalid={isSubmitted && !!errors.scale}
                    {...register("scale", {
                      pattern: {
                        value: /^(?!\s*$).+/,
                        message: "Only spaces are not allowed",
                      },
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.scale && errors.scale.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={6} lg={3}>
                <Form.Group className={!isSubmitted ? "mb-3" : null}>
                  <Form.Label>Language</Form.Label>
                  <Form.Control
                    type="text"
                    name={"languages"}
                    id={"web_tour_language"}
                    isValid={isSubmitted && !errors.languages}
                    isInvalid={isSubmitted && !!errors.languages}
                    {...register("languages", {
                      pattern: {
                        value: /^(?!\s*$).+/,
                        message: "Only spaces are not allowed",
                      },
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.languages && errors.languages.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={6} lg={3}>
                <Form.Group className={!isSubmitted ? "mb-3" : null}>
                  <Form.Label>Transportation</Form.Label>
                  <Form.Control
                    type="text"
                    name={"transportation"}
                    id={"web_tour_transportation"}
                    isValid={isSubmitted && !errors.transportation}
                    isInvalid={isSubmitted && !!errors.transportation}
                    {...register("transportation", {
                      pattern: {
                        value: /^(?!\s*$).+/,
                        message: "Only spaces are not allowed",
                      },
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.transportation && errors.transportation.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={6} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Max Group Size</Form.Label>
                  <Form.Control
                    type={"number"}
                    id="web_tour_max_size"
                    name="max_group_size"
                    {...register("max_group_size")}
                    style={getCustomStylesForInput("max_group_size")}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Price Includes</Form.Label>
                  <Form.Control
                    rows={4}
                    as="textarea"
                    name="price_includes"
                    className="text_area_input"
                    {...register("price_includes")}
                    style={getCustomStylesForInput("price_includes")}
                  />
                </Form.Group>
              </Col>
              <Col sm={12} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Price Excludes</Form.Label>
                  <Form.Control
                    rows={4}
                    as="textarea"
                    name="price_excludes"
                    className="text_area_input"
                    {...register("price_excludes")}
                    style={getCustomStylesForInput("price_excludes")}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    rows={4}
                    as="textarea"
                    name="description"
                    className="text_area_input"
                    {...register("description")}
                    style={getCustomStylesForInput("description")}
                  />
                </Form.Group>
              </Col>
              <Col sm={12} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Special Notes</Form.Label>
                  <Form.Control
                    rows={4}
                    as="textarea"
                    name="special_notes"
                    className="text_area_input"
                    {...register("special_notes")}
                    style={getCustomStylesForInput("special_notes")}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Categories
                    <span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Controller
                    control={control}
                    name="categories"
                    rules={{
                      required: "At least one category must be selected",
                      validate: (value) =>
                        value.length > 0 ||
                        "At least one category must be selected",
                    }}
                    render={({ field, fieldState }) => (
                      <>
                        <div
                          id="tour_categories"
                          className={"gridContainerStyle"}
                        >
                          {categoriesOptions?.map((category) => (
                            <Form.Check.Label
                              htmlFor={`category-${category.id}`}
                              className={"categoryCardStyle"}
                            >
                              <Form.Check
                                key={category.id}
                                type="checkbox"
                                id={`category-${category.id}`}
                                checked={selectedCategories.some(
                                  (sf) => sf.id === category.id
                                )}
                                onChange={() => {
                                  let updatedSelectedCategories;
                                  if (
                                    selectedCategories.some(
                                      (sf) => sf.id === category.id
                                    )
                                  ) {
                                    updatedSelectedCategories =
                                      selectedCategories.filter(
                                        (sf) => sf.id !== category.id
                                      );
                                  } else {
                                    updatedSelectedCategories = [
                                      ...selectedCategories,
                                      {
                                        id: category.id,
                                        category_name: category.category_name,
                                        categoryId: {
                                          category_name: category.category_name,
                                        },
                                      },
                                    ];
                                  }
                                  setSelectedCategories(
                                    updatedSelectedCategories
                                  );
                                  field.onChange(updatedSelectedCategories);
                                  trigger("categories");
                                }}
                              />

                              {category.category_name}
                            </Form.Check.Label>
                          ))}
                        </div>
                        {fieldState?.error && (
                          <div className="required_message">
                            {fieldState?.error?.message}
                          </div>
                        )}
                      </>
                    )}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
        </div>
        <Modal.Footer>
          {React.cloneElement(footer, { disabled: isFormDisabled })}
        </Modal.Footer>
      </Form>
    </>
  );
}

export default WebToursForm;
