import { FETCH_TOUR_VOUCHER_REQUEST } from "./tourVouchers";

export const ADD_WEB_TOUR_REQUEST = "ADD_WEB_TOUR_REQUEST";
export const ADD_WEB_TOUR_SUCCESS = "ADD_WEB_TOUR_SUCCESS";
export const ADD_WEB_TOUR_FAIL = "ADD_WEB_TOUR_FAIL";

export function addTourAction(formData, cb) {
  return {
    type: ADD_WEB_TOUR_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const UPDATE_WEB_TOUR_REQUEST = "UPDATE_WEB_TOUR_REQUEST";
export const UPDATE_WEB_TOUR_SUCCESS = "UPDATE_WEB_TOUR_SUCCESS";
export const UPDATE_WEB_TOUR_FAIL = "UPDATE_WEB_TOUR_FAIL";

export function updateTour(formData, cb) {
  return {
    type: UPDATE_WEB_TOUR_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const DELETE_WEB_TOUR_REQUEST = "DELETE_WEB_TOUR_REQUEST";
export const DELETE_WEB_TOUR_SUCCESS = "DELETE_WEB_TOUR_SUCCESS";
export const DELETE_WEB_TOUR_FAIL = "DELETE_WEB_TOUR_FAIL";

export function deleteTour(id, cb) {
  return {
    type: DELETE_WEB_TOUR_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const GET_ALL_WEB_TOURS_REQUEST = "GET_ALL_WEB_TOURS_REQUEST";
export const GET_ALL_WEB_TOURS_SUCCESS = "GET_ALL_WEB_TOURS_SUCCESS";
export const GET_ALL_WEB_TOURS_FAIL = "GET_ALL_WEB_TOURS_FAIL";

export function getAllToursAction(cb) {
  return {
    type: GET_ALL_WEB_TOURS_REQUEST,
    payload: {
      cb,
    },
  };
}

export const GET_SELECT_WEB_TOURS_REQUEST = "GET_SELECT_WEB_TOURS_REQUEST";
export const GET_SELECT_WEB_TOURS_SUCCESS = "GET_SELECT_WEB_TOURS_SUCCESS";
export const GET_SELECT_WEB_TOURS_FAIL = "GET_SELECT_WEB_TOURS_FAIL";

export function getSelectTours(service_date, direction_id, cb) {
  return {
    type: GET_SELECT_WEB_TOURS_REQUEST,
    payload: {
      service_date,
      direction_id,
      cb,
    },
  };
}

export const GET_WEB_TOURS_REQUEST = "GET_WEB_TOURS_REQUEST";
export const GET_WEB_TOURS_SUCCESS = "GET_WEB_TOURS_SUCCESS";
export const GET_WEB_TOURS_FAIL = "GET_WEB_TOURS_FAIL";

export function getToursAction(searchConfig, cb) {
  return {
    type: GET_WEB_TOURS_REQUEST,
    payload: {
      searchConfig,
      cb,
    },
  };
}

export const UPDATE_WEB_TOUR_PICKUP_REQUEST = "UPDATE_WEB_TOUR_PICKUP_REQUEST";
export const UPDATE_WEB_TOUR_PICKUP_SUCCESS = "UPDATE_WEB_TOUR_PICKUP_SUCCESS";
export const UPDATE_WEB_TOUR_PICKUP_FAIL = "UPDATE_WEB_TOUR_PICKUP_FAIL";

export function updateTourPickUpId(formData, cb) {
  return {
    type: UPDATE_WEB_TOUR_PICKUP_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const STATUS_WEB_TOUR_REQUEST = "STATUS_WEB_TOUR_REQUEST";
export const STATUS_WEB_TOUR_SUCCESS = "STATUS_WEB_TOUR_SUCCESS";
export const STATUS_WEB_TOUR_FAIL = "STATUS_WEB_TOUR_FAIL";

export function changeTourStatusAction(id, cb) {
  return {
    type: STATUS_WEB_TOUR_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const GET_SINGLE_WEB_TOUR_REQUEST = "GET_SINGLE_WEB_TOUR_REQUEST";
export const GET_SINGLE_WEB_TOUR_SUCCESS = "GET_SINGLE_WEB_TOUR_SUCCESS";
export const GET_SINGLE_WEB_TOUR_FAIL = "GET_SINGLE_WEB_TOUR_FAIL";

export function getSingleTour(id, cb) {
  return {
    type: GET_SINGLE_WEB_TOUR_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const EXPORT_WEB_TOURS_REQUEST = "EXPORT_WEB_TOURS_REQUEST";
export const EXPORT_WEB_TOURS_SUCCESS = "EXPORT_WEB_TOURS_SUCCESS";
export const EXPORT_WEB_TOURS_FAIL = "EXPORT_WEB_TOURS_FAIL";

export function exportExcelToursAction(searchConfig, cb) {
  return {
    type: EXPORT_WEB_TOURS_REQUEST,
    payload: { searchConfig, cb },
  };
}

export const GET_WEB_TOURS_GALLERY_REQUEST = "GET_WEB_TOURS_GALLERY_REQUEST";
export const GET_WEB_TOURS_GALLERY_SUCCESS = "GET_WEB_TOURS_GALLERY_SUCCESS";
export const GET_WEB_TOURS_GALLERY_FAIL = "GET_WEB_TOURS_GALLERY_FAIL";

export function getWebTourGallery(id, cb) {
  return {
    type: GET_WEB_TOURS_GALLERY_REQUEST,
    payload: { id, cb },
  };
}

export const UPDATE_WEB_TOURS_GALLERY_REQUEST =
  "UPDATE_WEB_TOURS_GALLERY_REQUEST";
export const UPDATE_WEB_TOURS_GALLERY_SUCCESS =
  "UPDATE_WEB_TOURS_GALLERY_SUCCESS";
export const UPDATE_WEB_TOURS_GALLERY_FAIL = "UPDATE_WEB_TOURS_GALLERY_FAIL";

export function updateWebTourGallery(id, formData, cb) {
  return {
    type: UPDATE_WEB_TOURS_GALLERY_REQUEST,
    payload: { id, formData, cb },
  };
}

export const UPDATE_WEB_TOUR_TYPE_REQUEST = "UPDATE_WEB_TOUR_TYPE_REQUEST";
export const UPDATE_WEB_TOUR_TYPE_SUCCESS = "UPDATE_WEB_TOUR_TYPE_SUCCESS";
export const UPDATE_WEB_TOUR_TYPE_FAIL = "UPDATE_WEB_TOUR_TYPE_FAIL";

export function updateSingleTourType(id, type, cb) {
  return {
    type: UPDATE_WEB_TOUR_TYPE_REQUEST,
    payload: { id, type, cb },
  };
}

export const FETCH_WEB_TOUR_REQUEST = "FETCH_WEB_TOUR_REQUEST";
export const FETCH_WEB_TOUR_SUCCESS = "FETCH_WEB_TOUR_SUCCESS";
export const FETCH_WEB_TOUR_FAIL = "FETCH_WEB_TOUR_FAIL";

export function fetchWebTours(cb) {
  return {
    type: FETCH_WEB_TOUR_REQUEST,
    payload: {
      cb,
    },
  };
}
