import Login from "../pages/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import TransferTypes from "../pages/transferTypes/TransferTypes";
import PreviewTransferTypes from "../pages/transferTypes/PreviewTransferTypes";
import Tours from "../pages/tours/Tours";
import PreviewTours from "../pages/tours/PreviewTours";
import PickUpPoints from "../pages/pickUpPoints/PickUpPoints";
import PreviewPickUpPoints from "../pages/pickUpPoints/PreviewPickUpPoints";
import TourVouchers from "../pages/tourVouchers/TourVouchers";
import PreviewTourVouchers from "../pages/tourVouchers/PreviewTourVouchers";
import TourTransfers from "../pages/tourTransfers/TourTransfers";
import PreviewTourTransfers from "../pages/tourTransfers/PreviewTourTransfers";
import Advertisements from "../pages/advertisements/Advertisements";
import PreviewAdvertisement from "../pages/advertisements/PreviewAdvertisement";
import Directions from "../pages/directions/Directions";
import PreviewDirections from "../pages/directions/PreviewDirections";
import Users from "../pages/users/Users";
import PreviewUsers from "../pages/users/PreviewUsers";
import TransferVouchers from "../pages/transferVouchers/TransferVouchers";
import PreviewTransferVouchers from "../pages/transferVouchers/PreviewTransferVouchers";
import EmailSetups from "../pages/emailSetups/EmailSetups";
import PreviewEmailSetups from "../pages/emailSetups/PreviewEmailSetups";
import Dashboard from "../pages/dashboard/Dashboard";
import TermsConditions from "../pages/termsAndConditions/TermsConditions";
import PreviewTermsConditions from "../pages/termsAndConditions/PreviewTermsConditions";
import MyAccount from "../pages/my_account/MyAccount";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import Logs from "../pages/logs/Logs";
import WebTours from "../pages/Website_Management/WebTours/WebTours";
import PreviewWebTours from "../pages/Website_Management/WebTours/PreviewWebTours";
import WebHotels from "../pages/Website_Management/WebHotels/WebHotels";
import PreviewWebHotels from "../pages/Website_Management/WebHotels/PreviewWebHotels";
import WebVillas from "../pages/Website_Management/WebVillas/WebVillas";
import PreviewWebVillas from "../pages/Website_Management/WebVillas/PreviewWebVillas";
import WebSuites from "../pages/Website_Management/WebSuites/WebSuites";
import PreviewWebSuites from "../pages/Website_Management/WebSuites/PreviewWebSuites";
import WebApartments from "../pages/Website_Management/WebApartments/WebApartments";
import PreviewWebApartments from "../pages/Website_Management/WebApartments/PreviewWebApartments";
import WebTourGallery from "../pages/Website_Management/WebTours/WebTourGallery";
import WebHotelGallery from "../pages/Website_Management/WebHotels/WebHotelGallery";
import HotelRooms from "../pages/Website_Management/WebHotels/HotelRooms/HotelRooms";
import PreviewHotelRoom from "../pages/Website_Management/WebHotels/HotelRooms/PreviewHotelRoom";
import HotelRoomGallery from "../pages/Website_Management/WebHotels/HotelRooms/HotelRoomGallery";
import WebVillaGallery from "../pages/Website_Management/WebVillas/WebVillaGallery";
import VillaRooms from "../pages/Website_Management/WebVillas/VillaRooms/VillaRooms";
import PreviewVillaRoom from "../pages/Website_Management/WebVillas/VillaRooms/PreviewVillaRoom";
import VillaRoomGallery from "../pages/Website_Management/WebVillas/VillaRooms/VillaRoomGallery";
import WebSuitesGallery from "../pages/Website_Management/WebSuites/WebSuitesGallery";
import SuiteRooms from "../pages/Website_Management/WebSuites/SuiteRooms/SuiteRooms";
import PreviewSuiteRoom from "../pages/Website_Management/WebSuites/SuiteRooms/PreviewSuiteRoom";
import SuiteRoomGallery from "../pages/Website_Management/WebSuites/SuiteRooms/SuiteRoomGallery";
import WebApartmentsGallery from "../pages/Website_Management/WebApartments/WebApartmentsGallery";
import ApartmentRooms from "../pages/Website_Management/WebApartments/ApartmentRooms/ApartmentRooms";
import PreviewApartmentRoom from "../pages/Website_Management/WebApartments/ApartmentRooms/PreviewApartmentRoom";
import ApartmentRoomGallery from "../pages/Website_Management/WebApartments/ApartmentRooms/ApartmentRoomGallery";
import Reservations from "../pages/reservations/Reservations/Reservations";
import PreviewReservation from "../pages/reservations/Reservations/PreviewReservation";
import RoomReservations from "../pages/reservations/roomReservations/RoomReservations";
import { Settings } from "@mui/icons-material";
import CompanySettings from "../pages/settings/Settings";

export const menuSideBarBottom = [
  {
    id: 3,
    path: "/my-profile",
    title: "My Profile",
    icon: <ManageAccountsIcon />,
    element: <MyAccount />,
    side_bar: true,
    access: ["Admin", "Supplier", "Driver", "Sales Agent", "Tour Manager"],
  },
  // {
  //   id: 2,
  //   path: "/settings",
  //   title: "Settings",
  //   icon: <Settings />,
  //   element: <CompanySettings />,
  //   side_bar: true,
  //   access: ["Admin", "Supplier", "Driver", "Sales Agent", "Tour Manager"],
  // },
  {
    id: 1,
    path: "/login",
    title: "Log Out",
    icon: <LogoutIcon />,
    element: <Login />,
    side_bar: true,
    default_access: true,
  },
];

export const mustPages = [
  {
    id: 1,
    path: "/login",
    title: "Login",
    icon: "",
    element: <Login />,
    side_bar: true,
  },
];

export const pages = [
  //////////////////// SIDEBAR ////////////////////

  // {
  //   id: '22',
  //   path: 'admin/roles',
  //   title: 'Roles',
  //   element: <Roles />,
  //   side_bar: true,
  // },
  // {
  //   id: '23',
  //   path: 'admin/roles/:id',
  //   title: 'Roles',
  //   element: <PreviewRoles />,
  // },

  {
    id: "110",
    path: "/my-profile",
    title: "My Profile",
    icon: <ManageAccountsIcon />,
    element: <MyAccount />,
    side_bar: false,
    access: ["Admin", "Supplier", "Driver", "Sales Agent", "Tour Manager"],
  },
  // {
  //   id: "120",
  //   path: "/settings",
  //   title: "Settings",
  //   icon: <Settings />,
  //   element: <CompanySettings />,
  //   side_bar: false,
  //   access: ["Admin", "Supplier", "Driver", "Sales Agent", "Tour Manager"],
  // },
  {
    id: "100",
    path: "/dashboard",
    title: "Dashboard",
    element: <Dashboard />,
    side_bar: true,
    access: ["Admin", "Supplier", "Driver", "Sales Agent", "Tour Manager"],
  },
  {
    id: "7",
    path: "/tours",
    title: "Tours",
    element: <Tours />,
    side_bar: true,
    access: ["Admin", "Supplier", "Tour Manager"],
  },
  {
    id: "8",
    path: "/tours/:id",
    title: "Tours",
    element: <PreviewTours />,
    access: ["Admin", "Tour Manager"],
  },
  {
    id: "1",
    path: "/vouchers",
    title: "Tour Vouchers",
    element: <TourVouchers />,
    side_bar: true,
    access: ["Admin", "Sales Agent", "Tour Manager"],
  },
  {
    id: "2",
    path: "/vouchers/:id",
    title: "Tour Vouchers",
    element: <PreviewTourVouchers />,
    access: ["Admin", "Sales Agent", "Tour Manager"],
  },
  {
    id: "3",
    path: "/transfers",
    title: "Transfers",
    element: <TourTransfers />,
    side_bar: true,
    access: ["Admin", "Driver", "Tour Manager"],
  },

  {
    id: "23",
    path: "/transfers_vouchers/:id",
    title: "Transfer Vouchers",
    element: <TransferVouchers />,
    access: ["Admin", "Driver", "Sales Agent", "Tour Manager"],
  },
  {
    id: "24",
    path: "/transfer_vouchers/:id",
    title: "Transfer Vouchers",
    element: <PreviewTransferVouchers />,
    access: ["Admin", "Sales Agent", "Tour Manager"],
  },
  {
    id: "22",
    path: "/transfer_vouchers",
    title: "Transfer Vouchers",
    element: <TransferVouchers />,
    side_bar: true,
    access: ["Admin", "Sales Agent", "Tour Manager"],
  },
  {
    id: "4",
    path: "/transfers/:id",
    title: "Transfer Vouchers",
    element: <PreviewTourTransfers />,
    access: ["Admin", "Sales Agent", "Tour Manager"],
  },

  // {
  //   id: "5",
  //   path: "/reservations",
  //   title: "Reservations",
  //   element: <Reservations />,
  //   side_bar: true,
  //   access: ["Admin", "Tour Manager"],
  // },
  // {
  //   id: "11",
  //   path: "/reservations/:id",
  //   title: "Reservations",
  //   element: <PreviewReservation />,
  //   access: ["Admin", "Tour Manager"],
  // },

  {
    id: "12",
    path: "/directions",
    title: "Directions",
    element: <Directions />,
    side_bar: true,
    access: ["Admin", "Tour Manager"],
  },
  {
    id: "6",
    path: "/directions/:id",
    title: "Directions",
    element: <PreviewDirections />,
    access: ["Admin", "Tour Manager"],
  },

  {
    id: "8",
    path: "/tours_vouchers/:id",
    title: "Tours",
    element: <TourVouchers />,
    access: ["Admin", "Supplier", "Tour Manager"],
  },
  {
    id: "9",
    path: "/pick_up_points",
    title: "Pick-Up Points",
    element: <PickUpPoints />,
    side_bar: true,
    access: ["Admin", "Tour Manager"],
  },
  {
    id: "10",
    path: "/pick_up_points/:id",
    title: "Pick-Up Points",
    element: <PreviewPickUpPoints />,
    access: ["Admin", "Tour Manager"],
  },
  // {
  //   id: '11',
  //   path: '/suppliers',
  //   title: 'Suppliers',
  //   element: <Suppliers />,
  //   side_bar: true,
  //   access: ['Admin'],
  // },
  // {
  //   id: '12',
  //   path: '/suppliers/:id',
  //   title: 'Suppliers',
  //   element: <PreviewSuppliers />,
  //   access: ['Admin'],
  // },
  {
    id: "20",
    path: "/users",
    title: "Users",
    element: <Users />,
    side_bar: true,
    access: ["Super Admin", "Admin", "Tour Manager"],
  },
  {
    id: "21",
    path: "/users/:id",
    title: "Users",
    element: <PreviewUsers />,
    access: ["Admin", "Tour Manager"],
  },
  // {
  //   id: '13',
  //   path: '/drivers',
  //   title: 'Drivers',
  //   element: <Drivers />,
  //   side_bar: true,
  //   access: ['Admin'],
  // },
  // {
  //   id: '14',
  //   path: '/drivers/:id',
  //   title: 'Drivers',
  //   element: <PreviewDrivers />,
  //   access: ['Admin'],
  // },
  {
    id: "30",
    path: "/setup",
    title: "Setup",
    access: ["Admin", "Tour Manager"],
    side_bar: true,
    children: [
      {
        id: "15",
        path: "/setup/transfer_types",
        title: "Transfer Types",
        element: <TransferTypes />,
        side_bar: true,
        access: ["Admin", "Tour Manager"],
      },
      {
        id: "16",
        path: "/setup/transfer_types/:id",
        title: "Transfer Types",
        element: <PreviewTransferTypes />,
        access: ["Admin", "Tour Manager"],
      },
      {
        id: "17",
        path: "/setup/advertisements",
        title: "Advertisements",
        element: <Advertisements />,
        side_bar: true,
        access: ["Admin", "Tour Manager"],
      },
      {
        id: "18",
        path: "/setup/advertisements/:id",
        title: "Advertisements",
        element: <PreviewAdvertisement />,
        access: ["Admin", "Tour Manager"],
      },
      // {
      //   id: '25',
      //   path: '/setup/settings',
      //   title: 'Settings',
      //   element: <CustomSettings />,
      //   side_bar: true,
      //   access: ['Admin'],
      // },
      // {
      //   id: '24',
      //   path: '/setup/settings/:id',
      //   title: 'Settings',
      //   element: <PreviewSettings />,
      //   side_bar: false,
      //   access: ['Admin'],
      // },
      // {
      //   id: '19',
      //   path: '/email_setup',
      //   title: 'Email Setup',
      //   element: <EmailSetup />,
      //   side_bar: true,
      //   access: ['Admin'],
      // },
      {
        id: "26",
        path: "/setup/email_setup",
        title: "Email Setup",
        element: <EmailSetups />,
        side_bar: true,
        access: ["Admin", "Tour Manager"],
      },
      {
        id: "27",
        path: "/setup/email_setup/:id",
        title: "Email Setup",
        element: <PreviewEmailSetups />,
        side_bar: false,
        access: ["Admin", "Tour Manager"],
      },
      {
        id: "28",
        path: "/setup/terms_conditions",
        title: "Terms and Conditions",
        element: <TermsConditions />,
        side_bar: true,
        access: ["Admin", "Tour Manager"],
      },
      {
        id: "29",
        path: "/setup/terms_conditions/:id",
        title: "Terms and Conditions",
        element: <PreviewTermsConditions />,
        side_bar: false,
        access: ["Admin", "Tour Manager"],
      },
    ],
  },
  {
    id: "40",
    path: "/logs",
    title: "Logs",
    element: <Logs />,
    side_bar: true,
    access: ["Super Admin"],
  },
  {
    id: "50",
    path: "/website-management",
    title: "Website Management",
    access: ["Admin", "Tour Manager"],
    side_bar: true,
    children: [
      //Tour
      {
        id: "51",
        path: "/website-management/website-tours",
        title: "Tours",
        element: <WebTours />,
        side_bar: true,
        access: ["Admin", "Tour Manager"],
      },
      {
        id: "52",
        path: "/website-management/website-tours/:id",
        title: "Tours",
        element: <PreviewWebTours />,
        access: ["Admin", "Tour Manager"],
      },
      {
        id: "53",
        path: "/website-management/website-tours/gallery/:id",
        title: "Tours",
        element: <WebTourGallery />,
        access: ["Admin", "Tour Manager"],
      },

      //Hotels && Rooms
      {
        id: "54",
        path: "/website-management/website-hotels",
        title: "Hotels",
        element: <WebHotels />,
        side_bar: true,
        access: ["Admin", "Tour Manager"],
      },
      {
        id: "55",
        path: "/website-management/website-hotels/:id",
        title: "Hotels",
        element: <PreviewWebHotels />,
        access: ["Admin", "Tour Manager"],
      },
      {
        id: "56",
        path: "/website-management/website-hotels/gallery/:id",
        title: "Hotels",
        element: <WebHotelGallery />,
        access: ["Admin", "Tour Manager"],
      },
      {
        id: "57",
        path: "/website-management/website-hotels/rooms/:id",
        title: "Hotels",
        element: <HotelRooms />,
        access: ["Admin", "Tour Manager"],
      },
      {
        id: "41",
        path: "/website-management/website-hotels/room/reservations/:hotel_room_id",
        title: "Hotels",
        element: <RoomReservations parentKey={"parent_hotel_id"} />,
        access: ["Admin", "Tour Manager"],
      },
      {
        id: "58",
        path: "/website-management/website-hotels/rooms/:id/:roomId",
        title: "Hotels",
        element: <PreviewHotelRoom />,
        access: ["Admin", "Tour Manager"],
      },
      {
        id: "59",
        path: "/website-management/website-hotels/rooms/gallery/:id/:roomId",
        title: "Hotels",
        element: <HotelRoomGallery />,
        access: ["Admin", "Tour Manager"],
      },

      //Villas && Rooms
      {
        id: "60",
        path: "/website-management/website-villas",
        title: "Villas",
        element: <WebVillas />,
        side_bar: true,
        access: ["Admin", "Tour Manager"],
      },
      {
        id: "61",
        path: "/website-management/website-villas/:id",
        title: "Villas",
        element: <PreviewWebVillas />,
        access: ["Admin", "Tour Manager"],
      },
      {
        id: "62",
        path: "/website-management/website-villas/gallery/:id",
        title: "Villas",
        element: <WebVillaGallery />,
        access: ["Admin", "Tour Manager"],
      },
      {
        id: "63",
        path: "/website-management/website-villas/rooms/:id",
        title: "Villas",
        element: <VillaRooms />,
        access: ["Admin", "Tour Manager"],
      },
      {
        id: "64",
        path: "/website-management/website-villas/rooms/:id/:roomId",
        title: "Villa",
        element: <PreviewVillaRoom />,
        access: ["Admin", "Tour Manager"],
      },
      {
        id: "65",
        path: "/website-management/website-villas/rooms/gallery/:id/:roomId",
        title: "Villa",
        element: <VillaRoomGallery />,
        access: ["Admin", "Tour Manager"],
      },

      //Suites
      {
        id: "66",
        path: "/website-management/website-suites",
        title: "Suites",
        element: <WebSuites />,
        side_bar: true,
        access: ["Admin", "Tour Manager"],
      },
      {
        id: "67",
        path: "/website-management/website-suites/:id",
        title: "Suites",
        element: <PreviewWebSuites />,
        side_bar: false,
        access: ["Admin", "Tour Manager"],
      },
      {
        id: "68",
        path: "/website-management/website-suites/gallery/:id",
        title: "Suites",
        element: <WebSuitesGallery />,
        access: ["Admin", "Tour Manager"],
      },
      {
        id: "69",
        path: "/website-management/website-suites/rooms/:id",
        title: "Suites",
        element: <SuiteRooms />,
        access: ["Admin", "Tour Manager"],
      },
      {
        id: "70",
        path: "/website-management/website-suites/rooms/:id/:roomId",
        title: "Suites",
        element: <PreviewSuiteRoom />,
        access: ["Admin", "Tour Manager"],
      },
      {
        id: "71",
        path: "/website-management/website-suites/rooms/gallery/:id/:roomId",
        title: "Suites",
        element: <SuiteRoomGallery />,
        access: ["Admin", "Tour Manager"],
      },

      //Apartments
      {
        id: "72",
        path: "/website-management/website-apartments",
        title: "Apartments",
        element: <WebApartments />,
        side_bar: true,
        access: ["Admin", "Tour Manager"],
      },
      {
        id: "73",
        path: "/website-management/website-apartments/:id",
        title: "Apartments",
        element: <PreviewWebApartments />,
        side_bar: false,
        access: ["Admin", "Tour Manager"],
      },
      {
        id: "74",
        path: "/website-management/website-apartments/gallery/:id",
        title: "Apartments",
        element: <WebApartmentsGallery />,
        access: ["Admin", "Tour Manager"],
      },
      {
        id: "75",
        path: "/website-management/website-apartments/rooms/:id",
        title: "Apartments",
        element: <ApartmentRooms />,
        access: ["Admin", "Tour Manager"],
      },
      {
        id: "76",
        path: "/website-management/website-apartments/rooms/:id/:roomId",
        title: "Apartments",
        element: <PreviewApartmentRoom />,
        access: ["Admin", "Tour Manager"],
      },
      {
        id: "77",
        path: "/website-management/website-apartments/rooms/gallery/:id/:roomId",
        title: "Apartments",
        element: <ApartmentRoomGallery />,
        access: ["Admin", "Tour Manager"],
      },
    ],
  },
];
