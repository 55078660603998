import { GridActionsCellItem } from "@mui/x-data-grid";
import { MdDelete, MdEdit } from "react-icons/md";
import { FaToggleOff, FaToggleOn } from "react-icons/fa";
import React from "react";
import moment from "moment";
import { BsFillSendFill } from "react-icons/bs";

const { REACT_APP_API_URL } = process.env;

export const getColumns = ({
  handleOpenSendEmailToUser,
  handleUpdateStatus,
  redirectToPreview,
  handleDelete,
}) => {
  return [
    {
      access: ["Super Admin", "Admin"],
      field: "id",
      headerName: "#",
      width: 60,
    },
    {
      access: ["Super Admin", "Admin"],
      field: "status",
      headerName: "Status",
      width: 100,
    },
    {
      access: ["Super Admin", "Admin"],
      field: "role",
      headerName: "Role",
      width: 150,
    },
    {
      access: ["Super Admin", "Admin"],
      field: "supplier_logo",
      headerName: "Logo",
      width: 100,
      renderCell: ({ row }) =>
        row?.supplier_logo && (
          <img
            style={{ objectFit: "contain" }}
            src={`${REACT_APP_API_URL}/supplier_logos/${row?.supplier_logo}`}
            alt=""
          />
        ),
    },
    {
      access: ["Super Admin", "Admin"],
      field: "full_name",
      headerName: "Full Name",
      width: 200,
    },
    {
      access: ["Super Admin", "Admin"],
      field: "user_name",
      headerName: "Username",
      width: 200,
    },
    {
      access: ["Super Admin", "Admin"],
      field: "email",
      headerName: "Email",
      width: 200,
    },
    {
      access: ["Super Admin", "Admin"],
      field: "phone_number",
      headerName: "Phone Number",
      width: 160,
      renderCell: ({ row }) => (
        <span style={{ cursor: "pointer", color: "#0d6efd" }}>
          <a
            onClick={(e) => e.stopPropagation()}
            style={{ color: "#0d6efd", textDecoration: "none" }}
            href={`tel:${row["phone_number"]}`}
          >
            {row["phone_number"]
              ? !row["phone_number"].startsWith("+")
                ? `+${row["phone_number"]}`
                : row["phone_number"]
              : ""}
          </a>
        </span>
      ),
    },
    {
      access: ["Super Admin", "Admin"],
      field: "driving_license_number",
      headerName: "Licence Number",
      width: 160,
    },
    {
      access: ["Super Admin", "Admin"],
      field: "passport_details",
      headerName: "Passport Details",
      width: 160,
    },
    {
      access: ["Super Admin", "Admin"],
      field: "registration_number",
      headerName: "Registration Number",
      width: 160,
    },
    {
      access: ["Super Admin", "Admin"],
      field: "vehicle_type",
      headerName: "Vehicle Type",
      width: 160,
    },
    {
      access: ["Super Admin", "Admin"],
      field: "createdAt",
      headerName: "Created Date",
      width: 200,
      renderCell: (params) =>
        moment(params?.createdAt || params?.row.createdAt).format(
          "MM/DD/YYYY HH:mm:ss"
        ),
    },
    {
      access: ["Super Admin", "Admin"],
      field: "createdBy.full_name",
      headerName: "Created By",
      width: 150,
      renderCell: ({ row }) => row?.createdBy?.full_name || "",
    },
    {
      access: ["Super Admin", "Admin"],
      field: "updatedAt",
      headerName: "Modified Date",
      width: 200,
      renderCell: (params) =>
        moment(params?.updatedAt || params?.row.updatedAt).format(
          "MM/DD/YYYY HH:mm:ss"
        ),
    },
    {
      access: ["Super Admin", "Admin"],
      field: "updatedBy.full_name",
      headerName: "Modified By",
      width: 150,
      renderCell: ({ row }) => row?.updatedBy?.full_name || "",
    },
    {
      headerName: "Actions",
      access: ["Super Admin", "Admin"],
      field: "actions",
      width: 150,
      cellClassName: "actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          onClick={() => {
            if (
              params?.row?.status === "Active" ||
              params?.status === "Active"
            ) {
              redirectToPreview(params?.row?.id || params?.id);
            }
          }}
          style={
            params?.row?.status === "Active" || params?.status === "Active"
              ? {
                  cursor: "pointer",
                }
              : {
                  cursor: "auto",
                  opacity: 0.4,
                }
          }
          className="test1"
          icon={<MdEdit />}
          label="Edit"
          title="View/Edit"
        />,
        <GridActionsCellItem
          onClick={(ev) => handleUpdateStatus(params?.row)}
          icon={
            params?.status === "Active" || params?.row?.status === "Active" ? (
              <FaToggleOn />
            ) : (
              <FaToggleOff />
            )
          }
          disabled={
            params?.status === "Pending" ||
            params?.row?.status === "Pending" ||
            params?.status === "NoPassword" ||
            params?.row?.status === "NoPassword"
          }
          style={
            params?.row?.status === "Active" ||
            params?.status === "Active" ||
            params?.row?.status === "Inactive" ||
            params?.status === "Inactive"
              ? {
                  cursor: "pointer",
                }
              : {
                  cursor: "auto",
                  opacity: 0.4,
                }
          }
          label="Status"
          title={
            params?.status === "Active" || params.row?.status === "Active"
              ? "Disable Login"
              : "Enable login"
          }
        />,
        <GridActionsCellItem
          onClick={() => handleOpenSendEmailToUser(params?.row || params)}
          className="test1"
          icon={<BsFillSendFill />}
          label="Edit"
          title="Resend Activation Email"
          access={["Admin", "Tour Manager"]}
          width={50}
          disabled={
            params?.row?.status === "Active" ||
            params?.status === "Active" ||
            params?.row?.status === "Inactive" ||
            params?.status === "Inactive"
          }
          style={
            params?.row?.status === "Active" ||
            params?.status === "Active" ||
            params?.row?.status === "Inactive" ||
            params?.status === "Inactive"
              ? {
                  cursor: "auto",
                  opacity: 0.4,
                }
              : {
                  cursor: "pointer",
                }
          }
        />,
        <GridActionsCellItem
          onClick={(ev) => handleDelete(params?.row)}
          label="Delete"
          title={"Delete"}
          icon={<MdDelete />}
        />,
      ],
    },
  ];
};
