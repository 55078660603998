import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Table from "../../../components/table";
import Pagination from "../../../components/pagination/Pagination";
import SearchFilterBar from "../../../components/search_filter_bar/SearchFilterBar";
import TableTitle from "../../../components/table_title/TableTitle";
import { setPage, sortAction } from "../../../store/actions/search";
import DeleteModal from "../../../components/modals/DeleteModal2";
import { getColumns } from "./column";
import {
  addSuitesAction,
  deleteSuites,
  changeSuitesStatusAction,
  exportExcelSuitesAction,
  getSuitesAction,
} from "../../../store/actions/websiteSuites";
import WebSuitesForm from "./WebSuitesForm";
import { isEmpty } from "lodash";
import UpdateStatusModal from "../../../components/modals/UpdateStatusModal";
import MobileLayout from "../../../components/mobileLayout/MobileLayout";
import { website_suites_mobile_column } from "../../../staticVariables/mobileColumns";
import DynamicModal from "../../../components/modals/DynamicModal";
import ModalButtons from "../../../components/modals/modalButtons/ModalButtons";

export const suitesStatus = [
  {
    id: "Active",
    color: "green",
  },
  {
    id: "Inactive",
    color: "#dc3545",
  },
];

function WebSuites() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [rowId, setRowId] = useState("");
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  let searchConfig = useSelector((store) => store.search);
  const account = useSelector((store) => store.users.account);
  let rows = useSelector((store) => store?.websiteSuites?.suites);
  const count = useSelector((store) => store.websiteSuites.count);
  let rowStatus = useSelector((store) => store?.websiteSuites?.status);
  let actionStatus = useSelector((store) => store?.websiteSuites?.actionStatus);
  // let exportExcelStatus = useSelector(
  //   (store) => store?.websiteSuites?.exportExcelStatus
  // );

  useEffect(() => {
    getSuitesList(searchConfig);
  }, []);

  const redirectToPreview = useCallback((params) => {
    navigate(`/website-management/website-suites/${params}`);
  }, []);

  const handleNavigateGallery = (id) => {
    navigate(`/website-management/website-suites/gallery/${id}`);
  };

  const handleNavigateToRooms = (id) => {
    navigate(`/website-management/website-suites/rooms/${id}`);
  };

  const handleClose = () => {
    setShow(false);
    setShowDelete(false);
    setShowStatus(false);
  };

  const handleDelete = (ev, params) => {
    ev.stopPropagation();
    setRowId(params);
    setShowDelete(true);
  };

  const handleConfirmDelete = () => {
    dispatch(
      deleteSuites(rowId?.id, (err, data) => {
        if (data) {
          toast.success("Successfully deleted.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          if (rows && rows.length === 1 && searchConfig.page !== 1) {
            searchConfig.page = searchConfig.page - 1;
            dispatch(setPage(searchConfig.page));
          }
          setRowId(null);
          setShowDelete(false);
          getSuitesList(searchConfig);
        }
        if (err) {
          toast.error(err.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          handleClose();
        }
      })
    );
  };

  const handleShow = () => {
    setShow(true);
  };

  const handleSort = (model) => {
    if (model.length) {
      searchConfig.sort = model;
      dispatch(sortAction(model));
    } else {
      model = searchConfig.sort;
    }
    getSuitesList(searchConfig);
  };

  const getSuitesList = (searchConfig) => {
    dispatch(getSuitesAction(searchConfig));
  };

  // const exportExcel = () => {
  //   dispatch(exportExcelSuitesAction(searchConfig));
  // };

  const handleUpdateStatus = (params) => {
    setRowId(params);
    setShowStatus(true);
  };

  const handleConfirmUpdateStatus = () => {
    dispatch(
      changeSuitesStatusAction(rowId?.id, (err, data) => {
        if (data) {
          toast.success("Successfully updated.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          getSuitesList(searchConfig);
        } else if (err) {
          toast.error(err.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
        }
        setShowStatus(false);
      })
    );
  };

  const columns = getColumns({
    rows,
    navigate,
    handleDelete,
    redirectToPreview,
    handleUpdateStatus,
    handleNavigateGallery,
    handleNavigateToRooms,
    role: !isEmpty(account) ? account.role : "",
  });

  const handleAddConfirm = async (data) => {
    dispatch(
      addSuitesAction(data, (err, data) => {
        if (data) {
          toast.success("Successfully created.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          dispatch(getSuitesAction(searchConfig));
          handleClose();
        } else if (err) {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
        }
      })
    );
  };

  return (
    <div className="admin_tables_block">
      <TableTitle
        title={"Website Suites"}
        handleShow={handleShow}
        addNew={
          (account && account.role === "Admin") ||
          account.role === "Tour Manager"
        }
        // exportExcel={
        //   (!isEmpty(account) && account.role === "Admin") ||
        //   account.role === "Tour Manager"
        //     ? exportExcel
        //     : null
        // }
        // exportExcelStatus={exportExcelStatus}
      />
      <SearchFilterBar
        getList={getSuitesList}
        columns={columns}
        setShowFilter={setShowFilter}
        showFilter={showFilter}
      />
      <Table
        status={rowStatus}
        rows={rows || []}
        columns={columns}
        setShow={setShow}
        setSort={handleSort}
        checkboxSelection={false}
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
      />
      <MobileLayout
        rows={rows || []}
        rowStatus={rowStatus}
        columns={columns}
        setShow={setShow}
        changeStatusHandler={handleConfirmUpdateStatus}
        parentTitle={"Website Suites"}
        link="/website-management/website-suites"
        mobileCol={website_suites_mobile_column}
        status={suitesStatus}
        search={getSuitesList}
      />
      <DynamicModal
        show={show}
        hide={() => setShow(false)}
        title={"Add New Suite"}
        body={
          <WebSuitesForm
            submit={handleAddConfirm}
            footer={
              <ModalButtons
                submit={handleAddConfirm}
                cancel={() => setShow(false)}
                isLoading={actionStatus === "pending"}
              />
            }
          />
        }
      />
      <DeleteModal
        show={showDelete}
        handleClose={() => handleClose()}
        confirm={(e) => handleConfirmDelete(e, rowId)}
        content={`You are about to delete the #${rowId?.id} suite. Are you sure?`}
        title={"Delete Website Suites"}
        actionStatus={actionStatus}
      />
      <UpdateStatusModal
        show={showStatus}
        handleConfirmUpdate={handleConfirmUpdateStatus}
        handleClose={handleClose}
        text={`You're about to ${
          rowId?.status === "Active" ? "de-activate" : "activate"
        }  the ${rowId?.suite_name} suite. Are you sure?`}
        title={"Update Website Suites"}
        actionStatus={actionStatus}
      />
      <Pagination
        rowStatus={rowStatus}
        count={count}
        limit={10}
        getList={getSuitesList}
      />
    </div>
  );
}

export default WebSuites;
